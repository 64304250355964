import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './styles/App.css';
import * as C from './components';
import * as P from './pages';
import { store } from './redux/store';

import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_APP != 'none') {
  import(`./styles/${process.env.REACT_APP_APP}.css`);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<C.Layout />}>
            <Route index element={<P.NotFound />} />
            <Route path='/barcode' element={<P.ScanBarcode />} />
            <Route path='/session/:session' element={<P.SessionPage />} />
            <Route path='/scanner' element={<P.CheckScanner />} />

            <Route path='/debug'>
              <Route index element={<P.Debug />} />
              <Route path='view-checks' element={<P.ViewChecks />} />
              <Route path='DetectRectangle' element={<P.DebugDetectRectangle />} />
              <Route path='StageCropPage' element={<P.StageCropPage />} />
              <Route path='VideoSource' element={<P.VideoSource />} />
            </Route>

            <Route path='/thankyou' element={<P.ThankYou />} />
            <Route path='/404' element={<P.NotFound />} />

            <Route path='*' element={<P.NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
