import axios from 'axios';
import config from './config';

class Api {
  static addr = config.serverAddress;
  static headers = config.headers;

  static getErrorMessage(error) {
    const { response } = error;

    const message = (response && response.data && response.data.error) || error.message;
    const ret = { error: message };

    try {
      ret.errNum = response.data.errNum;
    } catch (error) {}

    return ret;
  }

  static async getSession(session) {
    const url = '/api/session/' + session;
    const res = await Api.get(url);
    return res;
  }

  static async playground() {
    const url = '/debug/playground/';
    const res = await Api.get(url);
    return res;
  }

  static async sendLog(message) {
    try {
      const res = await Api.post('/debug/log', { message });
      return res;
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async newSession() {
    try {
      const res = await Api.post('/start');
      return res;
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async endSession(session) {
    const url = '/stop/' + session;
    try {
      return await Api.post(url);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async scanFront(session, data) {
    const url = '/scan/front/' + session;
    try {
      return await Api.post(url, data);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async scanBack(session, data) {
    const url = '/scan/back/' + session;
    try {
      return await Api.post(url, data);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }
  static async approveCheck(session, checkID) {
    const url = `/check/approve/${session}/${checkID}`;
    try {
      return await Api.post(url, {});
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async deleteCheck(session, checkID) {
    const url = `/check/${session}/${checkID}`;
    try {
      return await Api.delete(url);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async timeReport(data) {
    const url = '/debug/scan/time';
    try {
      return await Api.post(url, data);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }
  static async debug_getUser() {
    const url = '/debug/user';
    try {
      return await Api.get(url);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }
  static async debug_cropImage(data) {
    const url = '/debug/image/crop';
    try {
      return await Api.post(url, data);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async debug_validateMicr(data) {
    const url = '/debug/micr/validate';
    try {
      return await Api.post(url, data);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async debug_detect(data) {
    const url = '/debug/detect';
    try {
      return await Api.post(url, data);
    } catch (e) {
      return Api.getErrorMessage(e);
    }
  }

  static async debug_getChecks() {
    const url = '/debug/checks';
    const res = await Api.get(url);
    return res;
  }

  //////////////////////////////////////////////////////

  static async post(url, data = {}) {
    return await axios.post(Api.addr + url, data, Api.headers);
  }

  static async get(url) {
    return await axios.get(Api.addr + url, Api.headers);
  }

  static async delete(url) {
    return await axios.delete(Api.addr + url, Api.headers);
  }

  static async put(url, data = {}) {
    return await axios.put(Api.addr + url, data, Api.headers);
  }
}
export { Api };
