import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

class Alert {
  static defaultParams = {
    customClass: {
      confirmButton: 'popup-button-primary',
      cancelButton: 'popup-button-primary cancel',
    },
    confirmButtonText: 'אישור',
    cancelButtonText: 'ביטול',
  };

  static fire(params) {
    params = { ...Alert.defaultParams, ...params };
    return MySwal.fire(params);
  }

  static error(params) {
    const myParams = {
      ...Alert.defaultParams,
      icon: 'error',
      text: 'תקלה לא ידועה',
      timer: 5000,
    };
    params = { ...myParams, ...params };
    return MySwal.fire(params);
  }

  static warning(params) {
    const myParams = {
      ...Alert.defaultParams,
      icon: 'warning',
    };
    params = { ...myParams, ...params };
    return MySwal.fire(params);
  }

  static success(params) {
    const myParams = {
      ...Alert.defaultParams,
      icon: 'success',
      text: 'הפעולה בוצעה בהצלחה!',
      timer: 6000,
    };
    params = { ...myParams, ...params };
    return MySwal.fire(params);
  }

  static loading(params) {
    const myParams = {
      ...Alert.defaultParams,
      didOpen: () => {
        MySwal.showLoading();
      },
    };
    params = { ...myParams, ...params };

    return MySwal.fire(params);
  }

  static close() {
    return MySwal.close();
  }

  static showLoading() {
    return MySwal.showLoading();
  }
}
export { Alert };
