import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiRotateCcw, FiTrash2 } from 'react-icons/fi';

import { Api } from '../api';
import * as S from '../styles';
import { deleteCheck } from '../redux/sessionSlice';
import { Alert } from '../tools/alert';

export default function ChecksList() {
  const dispatch = useDispatch();
  const session = useSelector(({ session }) => session);
  const [showBack, setShowBack] = useState([]);

  function flipImage(check) {
    const checkID = check.checkID;
    let front = !showBack.includes(checkID);

    setShowBack(front ? [checkID, ...showBack] : showBack.filter((c) => c !== checkID));
  }

  function getFrontImage(c) {
    return c.front ? c.front : Api.addr + c.frontPath;
  }
  function getBackImage(c) {
    let path = Api.addr + '/images/none';
    if (c.back) {
      path = c.back;
    } else if (c.backPath) {
      path = Api.addr + c.backPath;
    }
    return path;
  }
  async function handleDeleteCheck(id) {
    try {
      const result = await Alert.fire({
        title: `למחוק את הצ'ק?`,
        showCancelButton: true,
        cancelButtonText: 'ביטול',
        confirmButtonText: 'אישור',
      });
      if (result.isConfirmed) {
        const res = await Api.deleteCheck(session.id, id);

        if (!res || res.error) throw { myError: res.error };
        Alert.success();

        dispatch(deleteCheck(id));
      }
    } catch ({ myError, response }) {
      const message = myError || (response && response.data && response.data.error) || 'תקלה לא ידועה';

      Alert.error({ title: 'המחיקה נכשלה', text: message, timer: 3000 });
    }
  }
  if (session.checks.length === 0) return;

  return (
    <>
      {[...session.checks].reverse().map((c) => {
        if (!c || (!c.frontPath && !c.backPath)) return '';
        let front = !showBack.includes(c.checkID);

        return (
          <CheckContainer key={'img-' + c.checkID}>
            <div dir='ltr' className='d-flex flex-column mb-3 gap-3 text-center'>
              {c.micr && (
                <div className='d-flex flex-column gap-2'>
                  <div className='text'>{c.micr}</div>
                  {/* <div className='text-small'>{c.confidence}%</div> */}
                </div>
              )}
            </div>
            <CheckImageContainer onClick={() => flipImage(c)} className='gap-2 d-flex flex-column'>
              {front ? (
                <img alt='check-front' height={130} src={getFrontImage(c)}></img>
              ) : (
                <img alt='check-back' height={130} src={getBackImage(c)}></img>
              )}
            </CheckImageContainer>

            <ButtonsContainer>
              <div className='button-icon' onClick={() => flipImage(c)}>
                <FiRotateCcw size={25} />
                <div className='text'>{front ? "צפייה בגב הצ'ק" : "צפייה בחזית הצ'ק"}</div>
              </div>
              {session.deletableCheck && (
                <div className='button-icon' onClick={() => handleDeleteCheck(c.checkID)}>
                  <FiTrash2 size={25} />
                  {session.multiScan ? <div className='text'>הסרת הצ'ק</div> : <div className='text'>סרוק שוב</div>}
                </div>
              )}
            </ButtonsContainer>
          </CheckContainer>
        );
      })}
    </>
  );
}

const CheckImageContainer = S.styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  min-height: 130px;
  border-radius: 8px;
  overflow: hidden;
  border: 3px dotted #eeeeee50;
`;

const ButtonsContainer = S.styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 15px 0;
`;

const CheckContainer = S.styled.div`
  min-width: fit-content;
  width: 70%;
  padding: 1rem;
  margin: 1rem 0 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 5px 1px #d4d4d4;
`;
