import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from '../../styles';
import { Alert } from '../../tools/alert';
import { Api } from '../../api';
import { cropByStages } from '../../tools/cv_old';

const DebugPage = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState();
  const playground = async () => {
    await Api.playground();
  };

  const validateMicrPopUp = async (micr) => {
    const userRes = await Alert.fire({
      showLoaderOnConfirm: true,

      title: 'Enter Micr with spaces',
      input: 'text', // Type of input (text, email, password, etc.)
      inputValue: micr,
    });
    if (userRes.dismiss) return;
    micr = userRes.value;
    const res = await Api.debug_validateMicr({ micr });
    let tryAgain = false;
    if (res && res.data) {
      const x = await Alert.fire({
        title: res.data,
      });
      tryAgain = x.isConfirmed;
    } else {
      const x = await Alert.error({ text: res && res.error ? res.error : 'Failed' });
      tryAgain = x.isConfirmed;
    }
    if (tryAgain) {
      validateMicrPopUp(micr);
    }
  };

  const handleCropRectangle = async () => {
    const { value: file } = await Alert.fire({
      title: 'בחר תמונה',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        'aria-label': 'העלאת תמונה',
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const image = e.target.result;

        const r = await cropByStages(image, 'mycanvas');

        Alert.fire({
          title: 'התמונה שהעלית',
          imageUrl: r,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropImage = async () => {
    const { value: file } = await Alert.fire({
      title: 'בחר תמונה',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        'aria-label': 'העלאת תמונה',
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const image = e.target.result;
        Alert.loading();
        const res = await Api.debug_cropImage({ image });
        if (!res || !res.data) {
          let errorMessage = 'תקלה לא ידועה';
          try {
            errorMessage = res.data.error;
          } catch (error) {}
          Alert.error({
            text: errorMessage,
            timer: false,
          });
        } else {
          Alert.fire({
            title: 'התמונה שהעלית',
            imageUrl: res.data,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDetect = async (area) => {
    const { value: file } = await Alert.fire({
      title: 'בחר תמונה',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        'aria-label': 'העלאת תמונה',
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const image = e.target.result;
        Alert.loading();
        const res = await Api.debug_detect({ image, area });
        if (!res || !res.data) {
          Alert.error({
            text: 'FAILED',
            timer: false,
          });
        } else {
          const { confidence, micr, duration } = res.data;
          Alert.fire({
            title: micr,
            html: `<div>
            <div> Confidence: ${confidence} </div>
            <div> Duration: ${duration} </div>
            </div>`,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const startSession = async () => {
    const data = {};
    const res = await Api.newSession(data);
    let r = 'FAILED';

    if (res && res.data) {
      if (res.data) {
        r = JSON.stringify(res.data, null, 2);
      } else if (res.error) {
        r = res.error;
      }
    }

    setResults(r);
  };

  const getUser = async () => {
    const res = await Api.debug_getUser();
    let r = 'FAILED';
    if (res && res.data) {
      if (res.data) {
        r = JSON.stringify(res.data, null, 2);
      } else if (res.error) {
        r = res.error;
      }
    }

    setResults(r);
  };

  return (
    <div dir='ltr' className='d-flex'>
      <BoxContainer className='w-25'>
        <div className='d-flex flex-column justify-content-center align-items-start gap-2'>
          <div className='text'>DEBUG</div>
          <button className='button-secondary' onClick={playground}>
            Playground
          </button>
          <button className='button-secondary' onClick={() => navigate('/debug/view-checks')}>
            View Checks
          </button>
          <button className='button-secondary' onClick={handleCropImage}>
            Crop Check
          </button>
          <button className='button-secondary' onClick={() => navigate('/debug/StageCropPage')}>
            Crop By Stages
          </button>
          <button className='button-secondary' onClick={() => handleDetect('BOTTOM')}>
            Detect Bottom
          </button>
          <button className='button-secondary' onClick={() => handleDetect('TOP')}>
            Detect Top
          </button>
          <button className='button-secondary' onClick={validateMicrPopUp}>
            Validate Micr
          </button>
          <button className='button-secondary' onClick={() => navigate('/debug/DetectRectangle')}>
            Detect Rectangle
          </button>
          <button className='button-secondary' onClick={() => navigate('/debug/VideoSource')}>
            Video Source
          </button>
        </div>
        <div className='d-flex mt-5 flex-column justify-content-center align-items-start gap-2'>
          <div className='text'>Session</div>
          <button className='button-secondary' onClick={startSession}>
            New Session
          </button>
          <button className='button-secondary' onClick={getUser}>
            Get User
          </button>
        </div>
      </BoxContainer>
      {results && (
        <div className='d-flex align-items-center flex-column w-50'>
          <textarea className='w-100 h-100 mx-5' value={results} readOnly />
          <div className='mt-2'>
            <button className='button-secondary' onClick={() => setResults()}>
              CLEAR
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const BoxContainer = S.styled.div`
  display: flex;
  width: 95%;

  border-radius: 5px;
  box-shadow: 0 0 5px 1px #eee;

  height: auto;
  margin: 50px;
  padding: 20px 50px;
  flex-direction: column;
  direction: ltr;
  align-items: baseline;
`;

export default DebugPage;
