import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    id: false,
    checks: [],
    url: '',
    status: false,
    currentCheck: false,
    isFrontScan: true,
    colorImages: [],
    multiScan: true,
    deletableCheck: true,
    withoutLayout: false,
  },
  reducers: {
    setIgnoreLayout: (state, action) => {
      state.withoutLayout = action.payload;
    },

    resetSessionData: (state, action) => {
      state.id = false;
      state.checks = [];
      state.colorImages = [];
      state.url = '';
      state.status = false;
      state.isFrontScan = true;
      state.currentCheck = false;
      state.multiScan = true;
      state.deletableCheck = true;
      state.withoutLayout = false;
    },

    setSession: (state, action) => {
      let { id, status, url, checks, scanType, deletableCheck } = action.payload;

      state.multiScan = scanType === 'MULTI';
      state.deletableCheck = deletableCheck;
      state.id = id;
      state.url = url;
      checks = checks
        .filter((c) => c.status == 'APPROVED')
        .map((c) => {
          const colorCheck = state.colorImages.find((i) => i.checkID === c.checkID);
          if (colorCheck) {
            c.front = colorCheck.front;
            c.back = colorCheck.back;
          }
          return c;
        });

      state.checks = checks;

      state.status = status;
    },

    updateColorImage: (state, action) => {
      const { checkID, image, isFront } = action.payload;
      const newColors = [...state.colorImages];
      let check = newColors.find((c) => c.checkID === checkID);
      if (!check) {
        check = { checkID };
        newColors.push(check);
      }
      if (isFront) {
        check.front = image;
      } else {
        check.back = image;
      }
      state.colorImages = newColors;
    },

    setSessionID: (state, action) => {
      state.id = action.payload;
    },

    setSessionUrl: (state, action) => {
      state.url = action.payload;
    },

    setSessionStatus: (state, action) => {
      state.status = action.payload;
    },

    setCurrentCheck: (state, action) => {
      state.isFrontScan = true;
      state.currentCheck = action.payload;
    },

    resetCurrentCheck: (state, action) => {
      state.isFrontScan = true;
      state.currentCheck = false;
    },

    isFrontScan: (state, action) => {
      state.isFrontScan = action.payload;
    },

    deleteCheck: (state, action) => {
      let checkID = action.payload;
      if (isNaN(action.payload)) {
        checkID = action.payload.checkID;
      }
      if (state.currentCheck == checkID) {
        state.currentCheck = false;
      }

      state.checks = state.checks.filter((c) => c.checkID !== checkID);
    },
  },
});

export const {
  isFrontScan,
  setSessionID,
  deleteCheck,
  setSessionUrl,
  setSession,
  setSessionStatus,
  resetCurrentCheck,
  setCurrentCheck,
  resetSessionData,
  updateColorImage,
  setIgnoreLayout,
} = sessionSlice.actions;

export default sessionSlice.reducer;
