import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as C from '../components';
import * as S from '../styles';
import { Api } from '../api';
import { resetCurrentCheck, isFrontScan, setIgnoreLayout, setCurrentCheck, resetSessionData } from '../redux/sessionSlice';

import useGetAndSetSession from '../hooks/useGetAndSetSession';

import { Alert } from '../tools/alert';

export default function CheckScanner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const session = useSelector(({ session }) => session);
  const message = useSelector(({ message }) => message);

  const [isLandscape, setIsLandscape] = useState(window.orientation === 90 || window.orientation === -90);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('בטעינה');
  const [detected, setDetected] = useState(false);
  const [failedCount, setFailedCount] = useState(0);
  // const [flip, setFlip] = useState(false);

  const { isLoading, error } = useGetAndSetSession(session.id);

  useEffect(() => {
    document.body.classList.add('scan-page');
    window.addEventListener('orientationchange', handleRotateScreen);
    dispatch(setIgnoreLayout(true));
    return () => {
      dispatch(setIgnoreLayout(false));
      window.removeEventListener('orientationchange', handleRotateScreen);
      document.body.classList.remove('scan-page');
    };
  }, []);

  if (!session.id) {
    navigate('/');
  }

  useEffect(() => {
    if (detected) {
      handleDetection(detected);
    }
  }, [detected]);

  const handleRotateScreen = () => {
    setIsLandscape(window.orientation === 90 || window.orientation === -90);
  };

  async function submitFront(data) {
    let timeReport = data.timeReport;

    const startApi = Date.now();
    if (failedCount >= 3) {
      data.forceMicr = true;
    }

    setLoadingMessage('מפענח פס מגנטי');

    const res = await Api.scanFront(session.id, data);
    if (!res) throw { myError: 'תקלה לא ידועה' };
    if (res.error) throw { myError: res.error, errNum: res.errNum };
    if (timeReport) {
      timeReport.api = Date.now() - startApi;
      timeReport.total = Date.now() - timeReport.total;

      Api.timeReport(timeReport);
    }

    const r = await Alert.fire({
      imageUrl: res.data.front,
      imageWidth: 'auto',
      imageHeight: 130,

      // text: "חזית הצ'ק צולמה בהצלחה!",
      html: <div dir='ltr'>{res.data.micr}</div>,
      confirmButtonText: "לצילום גב הצ'ק",
      showCancelButton: true,
      cancelButtonText: 'סריקה חוזרת',
      timer: false,
      customClass: {
        confirmButton: 'popup-button-primary',
        cancelButton: 'popup-button-secondary',
      },
    });
    let toRescanFront = r.dismiss === 'cancel';
    handleRescan(toRescanFront);
    if (toRescanFront) {
      await Api.deleteCheck(session.id, res.data.checkID);
    }
    return true;
  }

  const setFlip = (front, back) => {
    const element = document.getElementById('image-front-back');
    let img = front;
    const isFront = element.className.includes('front');
    if (isFront) {
      img = back;
      element.className = 'back';
    } else {
      element.className = 'front';
    }
    element.src = img;
  };

  async function submitBack(data) {
    let timeReport = data.timeReport;
    const startApi = Date.now();
    const res = await Api.scanBack(session.id, data);
    if (!res) throw { myError: 'תקלה לא ידועה' };
    if (res.error) throw { myError: res.error };

    if (timeReport) {
      timeReport.api = Date.now() - startApi;
      timeReport.total = Date.now() - timeReport.total;

      Api.timeReport(timeReport);
    }
    const r = await Alert.fire({
      html: (
        <img
          onClick={() => setFlip(res.data.front, res.data.back)}
          id='image-front-back'
          className='w-100'
          width='auto'
          height={130}
          alrt='scanned check'
          src={res.data.back}
        />
      ),

      title: "גב הצ'ק צולם בהצלחה!",
      confirmButtonText: 'אישור',
      cancelButtonText: 'סריקה חוזרת',
      showCloseButton: false,
      showCancelButton: true,
      timer: false,
      customClass: {
        confirmButton: 'popup-button-primary',
        cancelButton: 'popup-button-secondary ',
      },
    });

    if (r.isConfirmed) {
      await Api.approveCheck(session.id, data.checkID);
      return true;
    } else {
      handleRescan(false);
    }
  }
  async function handleFinish() {
    await Alert.fire({
      title: 'מסיים...',
      didOpen: async () => {
        Alert.showLoading();
        try {
          const res = await Api.endSession(session.id);
          if (res && res.data && res.data.redirect) {
            window.location.href = res.data.redirect;
          } else {
            Alert.close();
            return navigate('/thankyou');
          }
        } catch ({ response }) {
          const message = (response && response.data && response.data.error) || 'תקלה לא ידועה';

          Alert.error({ text: message ? message : 'לא הצלחנו לסיים', timer: 3000 });
        }
      },
    });
  }
  async function handleDetection(d) {
    setLoadingMessage('מעבד תמונה...');

    setLoading(true);
    let isDone = false;
    try {
      const timeReport = { front: session.isFrontScan, total: Date.now() };

      const checkID = session.currentCheck;
      const data = { checkID, image: d.base64, timeReport, toCrop: false };
      if (session.isFrontScan) {
        await submitFront(data);
      } else {
        isDone = await submitBack(data);
      }
    } catch ({ myError, message, errNum }) {
      if (errNum == 2) {
        setFailedCount(failedCount + 1);
      }
      message = myError || message;
      Alert.error({ text: message });
    }

    if (isDone) {
      setLoadingMessage();

      if (session.multiScan) {
        const { isConfirmed, dismiss } = await Alert.fire({
          text: "האם יש צ'קים נוספים?",
          cancelButtonText: "צ'ק נוסף",
          confirmButtonText: 'סיימתי',
          showCloseButton: false,
          showCancelButton: true,
          timer: false,
          customClass: {
            confirmButton: 'popup-button-primary',
            cancelButton: 'popup-button-secondary',
          },
        });

        if (isConfirmed) {
          await handleFinish();
        } else if (dismiss === 'cancel') {
          const nextID = (Math.random() * 1000000000).toFixed(0);
          dispatch(setCurrentCheck(nextID));
          handleRescan(true);
        } else {
          navigate('/session/' + session.id, { replace: true });
        }
      } else {
        await handleFinish();
      }
    }
    setLoading(false);
  }

  function handleRescan(front = session.isFrontScan) {
    setFailedCount(0);
    dispatch(isFrontScan(front));
    setLoading(false);
    setDetected(false);
  }

  function handleExit() {
    dispatch(resetCurrentCheck(false));
    dispatch(isFrontScan(true));
    navigate('/session/' + session.id, { replace: true });
  }

  if (isLoading) {
    return <div className='text-center text-dark'>טוען...</div>;
  }

  return (
    <>
      <C.Modal
        canExit={session.multiScan}
        onExit={handleExit}
        show={loading}
        loading={loading}
        text={message.message || loadingMessage}
      />

      <C.Frame
        canExit={session.multiScan}
        onExit={handleExit}
        toRotate={!isLandscape}
        success={message.level === 'success'}
        title={session.isFrontScan ? "יש לסרוק את חזית הצ'ק" : "יש לסרוק את גב הצ'ק"}
        text={message.message}
      />

      <div className='d-flex justify-content-center align-items-center'>
        <C.DetectRectangle front={session.isFrontScan} stop={loading} onDetection={setDetected} />
      </div>
    </>
  );
}
