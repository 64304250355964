import React, { useEffect, useRef, useState } from 'react';
import { CheckDetector } from '../tools/cv';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage, removeMessage } from '../redux/messageSlice';
import * as C from '../components';
import { delay, rotateBase64 } from '../tools/util';
import * as Sentry from '@sentry/react';
import { Api } from '../api';

const canvas_w = 1800;
let resloveCount = 0;

export default function DetectRectangle({ draw = true, front, stop = false, onDetection }) {
  const dispatch = useDispatch();
  const message = useSelector(({ message }) => message);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  let errList = [];
  async function handleMessage(m) {
    if (m.message && m.message !== message.message) {
      dispatch(setMessage(m));
    }
  }

  async function tick(startTime) {
    return new Promise((resolve, reject) => {
      requestAnimationFrame(async () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        if (!canvas || !video) return resolve(true);

        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        let toRotate = video.videoWidth < video.videoHeight;

        canvas.width = canvas_w;
        canvas.height = canvas_w / (video.videoWidth / video.videoHeight);

        if (toRotate) {
          canvas.width = canvas_w / (video.videoHeight / video.videoWidth);
          canvas.height = canvas_w;
        }

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
          if (draw) {
            // let res = await CheckDetector.detect(canvas, toRotate);
            let res = await CheckDetector.detectAndCrop(canvas, toRotate);

            if (res.success) {
              handleMessage({ message: 'החזק יציב', index: 2, level: 'success' });

              resloveCount++;
              if (resloveCount > 3) {
                let base64 = canvas.toDataURL('image/jpeg', 0.5); // Reducing the image quality
                resloveCount = 0;
                onDetection({ base64, canvas });
                return resolve(true);
              }
            } else {
              if (res.errType) {
                let secToWait = 20;
                const secondsFromStart = Math.round((Date.now() - startTime) / (1000 * secToWait)) * secToWait;

                if (secondsFromStart > 0) {
                  const errName = res.errType + secondsFromStart;
                  if (!errList.includes(errName)) {
                    errList.push(errName);
                    Sentry.captureMessage(errName);
                  }
                }
              }
              handleMessage({ message: res.message, index: 1, level: res.color ? res.color : 'warning' });
            }
          }
        } catch (error) {}

        return resolve(false);
      });
    });
  }

  useEffect(() => {
    const startTime = Date.now();
    const tickLoop = async () => {
      if (canvasRef.current && videoRef.current) {
        try {
          const done = await tick(startTime);
          if (!done) tickLoop();
        } catch (error) {
          alert(error);
        }
      }
    };
    dispatch(removeMessage());

    tickLoop();
  }, [stop]);

  return (
    <div>
      {!stop && <C.Video videoRef={videoRef} />}
      <canvas ref={canvasRef} id='video-rect' className='d-none'></canvas>
    </div>
  );
}
