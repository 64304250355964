import React, { useEffect } from 'react';

import { Alert } from '../tools/alert';

export default function Video({ hide, videoRef, width = '100%', height = '100%' }) {
  async function getContinuousCamera(idToIgnore) {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => {
        if (idToIgnore && idToIgnore == device.deviceId) return false;

        const isOk = device.kind.includes('video') && (!device.facingMode || device.facingMode.includes('environment'));
        return isOk;
      });

      for (let i = 0; i < videoDevices.length; i++) {
        const deviceId = videoDevices[i].deviceId;

        const constraints = { video: { deviceId: { exact: deviceId } } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        const track = stream.getVideoTracks()[0];

        try {
          const capabilities = track.getCapabilities();
          const isOk = isVideoStreamOk(capabilities);

          if (isOk) {
            return deviceId;
          }
        } catch (error) {
        } finally {
          track.stop();
        }
      }
    } catch (error) {
      Alert.error({ text: 'Error starting cameras:' + error.message });
    }
  }

  const isVideoStreamOk = ({ deviceId, focusMode, facingMode }) => {
    return facingMode.includes('environment') && (!focusMode || focusMode.includes('continuous'));
  };

  useEffect(() => {
    let videoStream;
    let videoTrack;
    const startVideo = async () => {
      try {
        //Getting camera
        const defaultConstraints = {
          audio: false,
          video: {
            facingMode: 'environment',
            focusMode: 'continuous',
            width: { ideal: 4096 },
            height: { ideal: 2160 },
          },
        };

        videoStream = await navigator.mediaDevices.getUserMedia(defaultConstraints);

        videoTrack = videoStream.getVideoTracks()[0];

        // throw { myError: 'המצלמה נמצאת בשימוש על ידי אפליקציה אחרת.' };

        const capabilities = videoTrack.getCapabilities();
        const firstStreamOk = isVideoStreamOk(capabilities);

        //If camera is not ok
        if (!firstStreamOk) {
          //Stoping the previous camera
          videoTrack.stop();
          //Choosing camera that has continuous focus mode
          const deviceId = await getContinuousCamera(capabilities.deviceId);

          //If found a camera with continuous focus mode
          if (deviceId) {
            const constraints = {
              audio: false,
              video: { deviceId: { exact: deviceId }, width: { ideal: 4096 }, height: { ideal: 2160 } },
            };

            //Starting the video
            videoStream = await navigator.mediaDevices.getUserMedia(constraints);
          } else {
            throw { myError: 'מכשיר זה אינו נתמך' };
          }
        }
        videoRef.current.srcObject = videoStream;
        videoRef.current.play();
      } catch (error) {
        let msg = error.myError;
        if (!msg) {
          if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
            // The user denied access to the camera.
            msg = 'יש לאפשר גישה למצלמה.';
          } else if (error.name === 'NotFoundError' || error.name === 'SourceUnavailableError') {
            // No camera device found or camera is already in use.
            msg = 'אין מצלמה זמינה.';
          } else {
            // Handle other errors.
            msg = 'שגיאה בגישה למצלמה: ' + error.message;
          }
        }
        Alert.error({ text: msg });
      }
    };

    const stopCamera = () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };

    startVideo();

    return () => {
      stopCamera();
    };
  }, []);

  return <video className={hide ? 'd-none' : ''} ref={videoRef} width={width} height={height} playsInline muted />;
}
