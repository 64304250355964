import { useNavigate } from 'react-router-dom';
import * as S from '../styles';

export default function Header() {
  const navigate = useNavigate();

  return (
    <div className='header-container'>
      <div
        className='text'
        onClick={() => {
          // navigate('/');
        }}
      >
        הפקדת צ'קים בצילום
      </div>
    </div>
  );
}
