import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'react-qr-code';

import * as S from '../styles';
import * as C from '../components';

import { Api } from '../api';
import { setSession, resetSessionData } from '../redux/sessionSlice';
import useGetAndSetSession from '../hooks/useGetAndSetSession';
import { Alert } from '../tools/alert';

let intervalId;
export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session = useSelector(({ session }) => session);

  useGetAndSetSession(session.id);
  const [tabPresses, setTabPresses] = useState(0);
  const [firstTabPressTime, setFirstTabPressTime] = useState(0);
  const timeConstraint = 1000; // Time constraint in milliseconds (adjust as needed)

  useEffect(() => {
    async function handleKeyPress(event) {
      if (event.key === 'Shift') {
        setTabPresses((prevTabPresses) => prevTabPresses + 1);
        if (tabPresses === 0) {
          setFirstTabPressTime(new Date().getTime());
        } else if (tabPresses === 1 && new Date().getTime() - firstTabPressTime <= timeConstraint) {
          newSession();
        } else {
          setTabPresses(0); // Reset the counter if the time constraint is not met
        }
      } else {
        setTabPresses(0); // Reset the counter if any other key is pressed
      }
    }

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [tabPresses, firstTabPressTime]);

  useEffect(() => {
    if (session.id) {
      getSessionLoop();
    }
  });

  function getSessionLoop() {
    clearInterval(intervalId);

    intervalId = setInterval(async () => {
      try {
        const response = await Api.getSession(session.id);
        if (response.data.session.status == 'DONE') {
          dispatch(resetSessionData());
        } else {
          dispatch(setSession(response.data.session));
        }
      } catch (error) {
        navigate('/');
        clearInterval(intervalId);
      }
    }, 3000);
  }

  const hanldeEnd = async () => {
    await Api.endSession(session.id);
    dispatch(resetSessionData());
    clearInterval(intervalId);
    navigate('/');
  };

  const newSession = async () => {
    dispatch(resetSessionData());
    clearInterval(intervalId);

    let res = await Api.newSession();
    if (res && res.data) {
      dispatch(setSession(res.data.session));
      getSessionLoop();
    } else {
      const text = res.error || 'תקלה לא ידועה';
      Alert.error({ text });
    }
  };
  const origin = window.location.origin.includes('localhost') ? 'https://192.168.1.143:3000' : window.location.origin; // FIXME:

  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      {!session.id && (
        <div className='mt-5'>
          <button className='button-primary' onClick={newSession}>
            יצירת ברקוד
          </button>
        </div>
      )}

      {session.id && (
        <>
          <div className='d-flex flex-column justify-content-center align-items-center mt-4 text-center gap-2'>
            <QRCode size={256} value={origin + session.url} />
            <p>{session.status}</p>
            <p>{session.id}</p>
          </div>
          <button className='button-primary my-3' onClick={() => navigate('/session/' + session.id, { replace: true })}>
            סרוק מהנייח
          </button>
          <button className='button-primary my-3' onClick={hanldeEnd}>
            סיים
          </button>
          <C.ChecksList />
        </>
      )}
    </div>
  );
}
