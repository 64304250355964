import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSession, resetSessionData } from '../redux/sessionSlice';
import { Api } from '../api';

const useGetAndSetSession = (id) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const session = useSelector(({ session }) => session);

  useEffect(() => {
    if (!id) {
      return setError('No Session');
    }

    if (id != session.id) {
      dispatch(resetSessionData());
    }

    // const MAX_TRY_COUNTER = 5;
    // let tryCounter = 0;

    const fetchData = async () => {
      // tryCounter++;
      try {
        const response = await Api.getSession(id);

        setIsLoading(false);
        dispatch(setSession(response.data.session));
      } catch (error) {
        // if (tryCounter < MAX_TRY_COUNTER) {
        //   setTimeout(() => {
        //     fetchData();
        //   }, 100);
        //   return;
        // }

        dispatch(resetSessionData());
        setIsLoading(false);

        let message = false;

        if (error.response && error.response.data) {
          message = error.response.data.error;
        }
        if (!message) {
          message = error.message;
        }

        if (!message) {
          message = 'בעיה לא ידועה';
        }
        setError(message);
      }
    };
    fetchData();
  }, [dispatch, session.id, id]);

  return { isLoading, error };
};

export default useGetAndSetSession;
