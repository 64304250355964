import React, { useEffect, useRef, useState } from 'react';
import { CheckDetector } from '../../tools/cv';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage, removeMessage } from '../../redux/messageSlice';
import * as C from '../../components';

const canvas_w = 1200;

export default function DebugDetectRectangle({ draw = true, stop = false, onDetection }) {
  const dispatch = useDispatch();
  const message = useSelector(({ message }) => message);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [text, setText] = useState('X');
  async function handleMessage(m) {
    if (m.message && m.message !== message.message) {
      dispatch(setMessage(m));
    }
  }

  async function tick() {
    return new Promise((resolve, reject) => {
      requestAnimationFrame(async () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        if (!canvas || !video) return resolve(true);

        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        let toRotate = video.videoWidth < video.videoHeight;

        canvas.width = canvas_w;
        canvas.height = canvas_w / (video.videoWidth / video.videoHeight);

        if (toRotate) {
          canvas.width = canvas_w / (video.videoHeight / video.videoWidth);
          canvas.height = canvas_w;
        }

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
          if (draw) {
            const detectBlur = false; //!front;
            let res = await CheckDetector.detect(canvas);
            if (res.success) {
              setText('החזק יציב');
              // handleMessage({ message: 'החזק יציב', index: 1, level: 'success' });
              // await perspectiveCut(canvas, res.corners);

              let base64 = canvas.toDataURL('image/jpeg', 0.2); // Reducing the image quality
              // onDetection({ rect: res.rect, base64, canvas });
              // handleMessage({ message: 'מעבד...', level: 'success' });

              // await delay(1000);

              // return resolve(true);
            } else {
              setText(res.message);

              handleMessage({ message: res.message, index: 1, level: res.color ? res.color : 'warning' });
            }
          }
        } catch {}

        return resolve(false);
      });
    });
  }

  useEffect(() => {
    const tickLoop = async () => {
      if (canvasRef.current && videoRef.current) {
        try {
          const done = await tick();
          if (!done) tickLoop();
        } catch (error) {
          alert(error);
        }
      }
    };
    dispatch(removeMessage());

    tickLoop();
  }, [stop]);

  return (
    <div>
      {!stop && <C.Video hide videoRef={videoRef} />}
      <canvas ref={canvasRef} id='video-rect'></canvas>
      <div className='p-5'>{text}</div>
    </div>
  );
}
