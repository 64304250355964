import { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';
import * as S from '../../styles';

import { Api } from '../../api';

export default function ViewChecks() {
  const [checks, setChecks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getChecksData = async () => {
    setIsLoading(true);
    const res = await Api.debug_getChecks();
    setIsLoading(false);
    setChecks(res.data.reverse());
  };

  useEffect(() => {
    getChecksData();
  }, []);

  const columns = [
    {
      name: 'Images',
      width: '350px',
      selector: 'checkID',
      cell: (row) => (
        <div className='d-flex flex-column m-2'>
          <img src={row.frontBase64} alt={row.name} width={300} />
          <img src={row.backBase64} alt={row.name} width={300} />
        </div>
      ),
    },
    { name: 'CMC7', sortable: true, wrap: true, selector: 'CMC7Read' },
    { name: 'CMC7Confidence', sortable: true, selector: 'CMC7Confidence' },
    { name: 'CMC7Duration', sortable: true, selector: 'CMC7Duration' },
    { name: 'OCR', sortable: true, selector: 'OCRRead' },
    { name: 'OCRConfidence', sortable: true, selector: 'OCRConfidence' },
    { name: 'OCRDuration', sortable: true, selector: 'OCRDuration' },
    { name: 'TotalDuration', sortable: true, selector: 'TotalDuration' },
  ];

  if (isLoading) {
    return <Center>טוען...</Center>;
  }

  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <DataTable direction='ltr' title='DEBUG' columns={columns} data={checks} />
    </div>
  );
}

const Center = S.styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;
