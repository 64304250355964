import * as S from '../styles';

export default function NotFound() {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
      <div className='text-large'>404</div>
      <div className='text'>העמוד לא נמצא</div>
    </div>
  );
}
