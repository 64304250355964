import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as S from '../styles';
import * as C from '../components';
import * as P from '../pages';

import { Api } from '../api';
import { setCurrentCheck, resetSessionData } from '../redux/sessionSlice';
import useGetAndSetSession from '../hooks/useGetAndSetSession';
import { Alert } from '../tools/alert';

export default function SessionPage() {
  const session = useSelector(({ session }) => session);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sessionFromUrl = window.location.pathname.split('/')[2];

  const { isLoading, error } = useGetAndSetSession(sessionFromUrl);

  if (isLoading) {
    return (
      <Center>
        <div className='spinner-border text-light' role='status'>
          <span className='sr-only' />
        </div>
      </Center>
    );
  }

  if (error) {
    return <P.NotFound />;
  }

  async function handleFinish() {
    await Alert.fire({
      title: 'מסיים...',
      didOpen: async () => {
        Alert.showLoading();
        try {
          const res = await Api.endSession(session.id);
          if (res && res.data && res.data.redirect) {
            window.location.href = res.data.redirect;
          } else {
            Alert.close();
            return navigate('/thankyou');
          }
        } catch ({ response }) {
          const message = (response && response.data && response.data.error) || 'תקלה לא ידועה';

          Alert.error({ text: message ? message : 'לא הצלחנו לסיים', timer: 3000 });
        }
      },
    });
  }

  function handleNewCheck() {
    const nextID = (Math.random() * 1000000000).toFixed(0);
    dispatch(setCurrentCheck(nextID));
    return navigate('/scanner');
  }

  if (session && session.checks.length == 0 && session.status !== 'DONE') {
    return handleNewCheck();
  }

  const getScanNewButton = () => {
    const canScanMore = session.multiScan ? true : session.checks.length == 0;
    const inProgress = session.status !== 'DONE';
    if (canScanMore && inProgress) {
      return (
        <button className='button-primary' onClick={handleNewCheck}>
          צילום צ'ק
        </button>
      );
    }
  };

  const getDoneButton = () => {
    const canDone = session.checks.length > 0;
    const inProgress = session.status !== 'DONE';
    if (canDone && inProgress) {
      return (
        <button className='button-primary m-4' onClick={handleFinish}>
          סיים
        </button>
      );
    }
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      {session.status === 'DONE' && 'הסתיים בהצלחה!'}
      {getScanNewButton()}
      <C.ChecksList />
      {getDoneButton()}
    </div>
  );
}

const Center = S.styled.div`
  position: fixed;
  top:0;
  left:0;
  background: #858585;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const LoadingText = S.styled.div`
  font-weight: 600;
  font-size: small;
  color: ${S.softblack};
`;
