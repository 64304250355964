import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetSessionData } from '../redux/sessionSlice';

export default function ThankYou() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSessionData());
  }, []);
  return (
    <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
      <div className='text-large'>סיימנו!</div>
      <div className='text'>התהליך הסתיים בהצלחה</div>
    </div>
  );
}
