import mainConfig from './config';
import devConfig from './dev_config';

const MODE_DEV = process.env.NODE_ENV === 'development';
let config = mainConfig;

if (MODE_DEV) {
  config = { ...config, ...devConfig };
}

export default config;
