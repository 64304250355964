import cv from '@techstark/opencv-js';

class CheckDetector {
  static async debugDetectAndCrop(canvas, rotate) {
    const mapToDelete = [];
    try {
      let src = cv.imread(canvas);
      mapToDelete.push(src);

      let dst = cv.imread(canvas);
      mapToDelete.push(dst);

      if (rotate) {
        src = CheckDetector.rotate(src);
        dst = CheckDetector.rotate(dst);
      }

      src = CheckDetector.dilate(src);
      // cv.imshow(canvas, src);
      // return;
      src = CheckDetector.toGrayscale(src);

      src = CheckDetector.blur(src);

      src = CheckDetector.removeNoise(src);

      src = CheckDetector.canny(src);

      src = CheckDetector.adaptiveThreshold(src);

      let cnt = CheckDetector.findMaxCountor(src);
      mapToDelete.push(cnt);

      let points = CheckDetector.findPoints(cnt, src);

      let corners = CheckDetector.nameCornerPoints(points);
      cv.imshow(canvas, src);

      //Perspective
      let pers = CheckDetector.perspective(dst, dst, corners);
      mapToDelete.push(pers.dstTri);
      // Crop;
      dst = CheckDetector.crop(dst, pers.dstTri);
      mapToDelete.push(dst);

      // cv.imshow(canvas, dst);
      cv.imshow(canvas, src);
    } catch (e) {
      console.log(e);
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch {}
      }
    }
  }

  static async detectAndCrop(canvas, rotate) {
    let ret = { success: false, message: '' };
    if (canvas.width * canvas.height === 0) return ret;

    const mapToDelete = [];
    try {
      let src = cv.imread(canvas);
      mapToDelete.push(src);

      let dst = cv.imread(canvas);
      mapToDelete.push(dst);

      if (rotate) {
        src = CheckDetector.rotate(src);
        dst = CheckDetector.rotate(dst);
      }

      src = CheckDetector.dilate(src);

      src = CheckDetector.toGrayscale(src);

      src = CheckDetector.blur(src);

      src = CheckDetector.removeNoise(src);

      src = CheckDetector.canny(src);

      src = CheckDetector.adaptiveThreshold(src);

      let cnt = CheckDetector.findMaxCountor(src);
      mapToDelete.push(cnt);

      let approx = CheckDetector.chooseApproxContour(cnt);
      mapToDelete.push(approx);

      if (!approx) throw { myError: `וודא שהצק בתוך המסגרת`, errType: 'NoFrame' };

      let corners = CheckDetector.getCornersFromContour(approx);

      if (!isValidAngles(corners)) throw { myError: `החזק ישר`, errType: 'InvalidAngles' };

      const { recWidth, recHeight } = corners;

      const isValidSize = CheckDetector.validateSize(recWidth, src.size().width);

      if (!isValidSize) throw { myError: `וודא שהצ'ק ממלא את המסגרת`, errType: 'InvalidSize' };

      const isValidRatio = CheckDetector.validateRatio(recWidth, recHeight);

      if (!isValidRatio) throw { myError: `וודא שהצ'ק על רקע כהה`, errType: 'InvalidRatio' };

      //Perspective
      let pers = CheckDetector.perspective(dst, dst, corners);
      mapToDelete.push(pers.dstTri);

      // Crop;
      dst = CheckDetector.crop(dst, pers.dstTri);
      mapToDelete.push(dst);

      ret.success = true;

      cv.imshow(canvas, dst);
    } catch (error) {
      ret.message = error.myError ? error.myError : `תקלה בזיהוי הצ'ק`;
      if (!error.myError) {
        console.log('error:', error);
      }
      ret.errType = error.myError ? error.errType : 0;
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch {}
      }
    }
    return ret;
  }

  static crop(img, mat) {
    let rect = cv.boundingRect(mat);
    return img.roi(rect);
  }

  static order_points(pts) {
    let rect = Array.from({ length: 4 }, () => [0, 0]);

    let s = pts.map((p) => p.x + p.y);
    rect[0] = pts[s.indexOf(Math.min(...s))];
    rect[2] = pts[s.indexOf(Math.max(...s))];
    let diff = pts.map((p) => p.x - p.y);
    rect[1] = pts[diff.indexOf(Math.min(...diff))];
    rect[3] = pts[diff.indexOf(Math.max(...diff))];

    return rect;
  }

  static perspective(src, original, corners) {
    const mapToDelete = [];
    try {
      let { tr, br, bl, tl } = corners;

      let widthA = Math.sqrt((br.x - bl.x) ** 2 + (br.y - bl.y) ** 2);
      let widthB = Math.sqrt((tr.x - tl.x) ** 2 + (tr.y - tl.y) ** 2);
      let maxWidth = Math.max(widthA, widthB);

      let heightA = Math.sqrt((tr.x - br.x) ** 2 + (tr.y - br.y) ** 2);
      let heightB = Math.sqrt((tl.x - bl.x) ** 2 + (tl.y - bl.y) ** 2);
      let maxHeight = Math.max(heightA, heightB);

      let destination_corners = [
        [0, 0],
        [maxHeight, 0],
        [maxHeight, maxWidth],
        [0, maxWidth],
      ];
      corners = CheckDetector.order_points([tr, br, bl, tl]);

      let corns = [];
      for (const cor of corners) {
        corns.push(cor.x);
        corns.push(cor.y);
      }

      let dest_corns = [];
      for (const cor of destination_corners) {
        dest_corns.push(cor[1]);
        dest_corns.push(cor[0]);
      }

      let srcTri = cv.matFromArray(4, 1, cv.CV_32FC2, corns);
      mapToDelete.push(srcTri);

      let dstTri = cv.matFromArray(4, 1, cv.CV_32FC2, dest_corns);
      let M = cv.getPerspectiveTransform(srcTri, dstTri);
      mapToDelete.push(M);

      let size = new cv.Size(destination_corners[1][1], destination_corners[1][0]);

      cv.warpPerspective(original, src, M, size);

      return { src, original, dstTri };
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch (e) {}
      }
    }
  }

  static nameCornerPoints(points) {
    let corners = [];
    for (const p of points) {
      corners.push(new cv.Point(p.x, p.y));
    }

    corners.sort((a, b) => a.x - b.x);
    let tl = corners[0].y < corners[1].y ? corners[0] : corners[1];
    let bl = corners[0].y < corners[1].y ? corners[1] : corners[0];
    let tr = corners[2].y < corners[3].y ? corners[2] : corners[3];
    let br = corners[2].y < corners[3].y ? corners[3] : corners[2];

    const recWidth = Math.sqrt(Math.pow(tl.x - tr.x, 2) + Math.pow(tl.y - tr.y, 2));
    const recHeight = Math.sqrt(Math.pow(tl.x - bl.x, 2) + Math.pow(tl.y - bl.y, 2));

    return { tr, bl, br, tl, recWidth, recHeight };
  }

  static getCornersFromContour(cnt) {
    let matVector;
    try {
      matVector = new cv.MatVector();
      matVector.push_back(cnt);

      let corners = [];
      corners.push(new cv.Point(matVector.get(0).data32S[6], matVector.get(0).data32S[7])); //A
      corners.push(new cv.Point(matVector.get(0).data32S[4], matVector.get(0).data32S[5])); //B
      corners.push(new cv.Point(matVector.get(0).data32S[0], matVector.get(0).data32S[1])); //C
      corners.push(new cv.Point(matVector.get(0).data32S[2], matVector.get(0).data32S[3])); //D

      // const redColor = new cv.Scalar(0, 0, 255, 255);
      // for (const corner of corners) {
      //   cv.circle(src, corner, 5, redColor, 15);
      // }

      corners.sort((a, b) => a.x - b.x);
      let tl = corners[0].y < corners[1].y ? corners[0] : corners[1];
      let bl = corners[0].y < corners[1].y ? corners[1] : corners[0];
      let tr = corners[2].y < corners[3].y ? corners[2] : corners[3];
      let br = corners[2].y < corners[3].y ? corners[3] : corners[2];

      const recWidth = Math.sqrt(Math.pow(tl.x - tr.x, 2) + Math.pow(tl.y - tr.y, 2));
      const recHeight = Math.sqrt(Math.pow(tl.x - bl.x, 2) + Math.pow(tl.y - bl.y, 2));

      return { tr, bl, br, tl, recWidth, recHeight };
    } finally {
      try {
        matVector.delete();
      } catch {}
    }
  }

  static _chooseApproxContour2(cnt, src) {
    const mapToDelete = [];

    try {
      let choosenApprox = false;
      let xim = {};
      let yim = {};
      let allpoints = {};
      let arcl = cv.arcLength(cnt, true);

      for (let epsilon = 0; epsilon < arcl / 10; epsilon += 3) {
        let approx = new cv.Mat();
        mapToDelete.push(approx);

        cv.approxPolyDP(cnt, approx, epsilon, true);
        const numOfPoints = approx.rows;

        if (numOfPoints < 3 || numOfPoints > 6) {
          continue;
        }

        choosenApprox = approx;

        let color = new cv.Scalar(
          Math.round(Math.random() * 255),
          Math.round(Math.random() * 255),
          Math.round(Math.random() * 255)
        );

        let pointsFound = [];

        for (let i = 0; i < choosenApprox.rows; i++) {
          let x = choosenApprox.data32S[i * 2];
          let y = choosenApprox.data32S[i * 2 + 1];

          let point = new cv.Point(x, y);
          cv.circle(src, point, 7, color, -1);
          pointsFound.push(point);
          allpoints[`${x},${y}`] = allpoints[`${x},${y}`] ? allpoints[`${x},${y}`] + 1 : 1;
        }

        console.log(pointsFound.length, epsilon, JSON.stringify(pointsFound));
      }
      // console.log(xim);

      // let sortedArray = Object.entries(allpoints).sort((a, b) => b[1] - a[1]);
      // sortedArray = sortedArray.slice(0, 6);

      // for (let p of sortedArray) {
      //   p = p[0].split(',');
      //   let x = Number(p[0]);
      //   let y = Number(p[1]);

      //   xim[x] = xim[x] ? xim[x] + 1 : 1;
      //   yim[y] = yim[y] ? yim[y] + 1 : 1;

      //   let point = new cv.Point(x, y);
      //   let color = new cv.Scalar(
      //     Math.round(Math.random() * 255),
      //     Math.round(Math.random() * 255),
      //     Math.round(Math.random() * 255)
      //   );
      //   cv.circle(src, point, 7, color, -1);
      // }

      // let bestXim = groupCloseValues(xim, 10);
      // let bestYim = groupCloseValues(yim, 10);

      // //  for (let i = 0; i < sortedArray.length; i++) {
      // //     let p = sortedArray[i];
      // //     p  =
      // //  }

      // let xxx = [
      //   [127, 624],
      //   [126, 113],
      //   [350, 113],
      //   [350, 624],
      // ];
      // for (const a of xxx) {
      //   let x = a[0];
      //   let y = a[1];

      //   let point = new cv.Point(x, y);
      //   cv.circle(src, point, 7, [255, 0, 0, 0], -1);
      // }

      // console.log(sortedArray);
      // console.log(xim);
      // console.log(yim);

      return choosenApprox;
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch {}
      }
    }
  }

  static getRandomColor() {
    let color = new cv.Scalar(Math.round(Math.random() * 255), Math.round(Math.random() * 255), Math.round(Math.random() * 255));
    return color;
  }

  static findPoints(cnt, src) {
    const mapToDelete = [];

    try {
      const allPoints = [];
      const arc = cv.arcLength(cnt, true);

      for (let epsilon = 0; epsilon < arc / 10; epsilon += 3) {
        const approx = new cv.Mat();
        mapToDelete.push(approx);

        cv.approxPolyDP(cnt, approx, epsilon, true);
        const numOfPoints = approx.rows;

        if (6 < numOfPoints || numOfPoints < 3) {
          continue;
        }

        for (let i = 0; i < approx.rows; i++) {
          const x = approx.data32S[i * 2];
          const y = approx.data32S[i * 2 + 1];

          allPoints.push([x, y]);

          // const color = CheckDetector.getRandomColor();
          // const point = new cv.Point(x, y);

          // cv.circle(src, point, 7, color, -1);
        }
      }

      const pairPointCount = CheckDetector.countPointPairs(allPoints);

      const maxDistance = 20;
      const mergedPoints = CheckDetector.mergePointsWithinDistance(pairPointCount, maxDistance);

      const xim = mergedPoints.map((p) => p.x);
      const simiX = CheckDetector.countSimilarNumbers(xim, mergedPoints, ['x', 'y'], maxDistance);

      const yim = mergedPoints.map((p) => p.y);
      const simiY = CheckDetector.countSimilarNumbers(yim, mergedPoints, ['y', 'x'], maxDistance);

      const countX = simiX.map((s) => ({ value: s, paired: [] }));
      const countY = simiY.map((s) => ({ value: s, paired: [] }));

      for (const o of countX) {
        for (const p of mergedPoints) {
          if (Math.abs(p.x - o.value) <= maxDistance) {
            let found = countY.find((c) => Math.abs(c.value - p.y) <= maxDistance);
            if (found) {
              o.paired.push(found.value);
              found.paired.push(o.value);
            }
          }
        }
      }

      const yCandidates = [];
      const xCandidates = [];

      for (const o of countX) {
        if (o.paired.length == 2) {
          yCandidates.push(...o.paired);
        }
      }

      for (const o of countY) {
        if (o.paired.length == 2) {
          xCandidates.push(...o.paired);
        }
      }

      let realPointsFound = [];

      for (const x of xCandidates) {
        for (const y of yCandidates) {
          let found = pairPointCount.find((p) => Math.abs(x - p.x) <= maxDistance && Math.abs(y - p.y) <= maxDistance);
          if (found) {
            realPointsFound.push(found);
          }
        }
      }

      realPointsFound = Array.from(new Set(realPointsFound));
      realPointsFound = realPointsFound.slice(0, 4);

      if (realPointsFound.length == 3) {
        let lengths = [];
        lengths.push({ length: CheckDetector.pointDistance(realPointsFound[1], realPointsFound[2]), points: [1, 2] });
        lengths.push({ length: CheckDetector.pointDistance(realPointsFound[0], realPointsFound[2]), points: [0, 2] });
        lengths.push({ length: CheckDetector.pointDistance(realPointsFound[1], realPointsFound[0]), points: [1, 0] });

        let maxIndex = 0;
        let maxLength = 0;

        for (let i = 0; i < lengths.length; i++) {
          if (lengths[i].length > maxLength) {
            maxLength = lengths[i].length;
            maxIndex = i;
          }
        }

        let p1 = realPointsFound[lengths[maxIndex].points[0]];
        let p2 = realPointsFound[lengths[maxIndex].points[1]];

        let centerX = (p2.x + p1.x) / 2;
        let centerY = (p2.y + p1.y) / 2;
        let center = { x: centerX, y: centerY };

        let p3 = realPointsFound[maxIndex];

        let lastPointX = center.x < p3.x ? p1.x : p2.x;
        let lastPointY = center.y < p3.y ? p1.y : p2.y;
        // let lastPointX = center.x > p3.x ? p3.x + 2 * Math.abs(center.x - p3.x) : p3.x - 2 * Math.abs(center.x - p3.x);
        // let lastPointY = center.y > p3.y ? p3.y + 2 * Math.abs(center.y - p3.y) : p3.y - 2 * Math.abs(center.y - p3.y);

        realPointsFound.push({ x: lastPointX, y: lastPointY });
      }
      if (realPointsFound.length == 0) {
        console.log('NOT FOUND!');
      } else {
        console.log(realPointsFound);
      }
      const color = CheckDetector.getRandomColor();
      for (const p of realPointsFound) {
        const point = new cv.Point(p.x, p.y);

        cv.circle(src, point, 7, color, -1);
      }

      return realPointsFound;
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch {}
      }
    }
  }

  static countPointPairs(pointArray) {
    const pointCounts = {};

    for (const [x, y] of pointArray) {
      const pointKey = `[${x},${y}]`;
      if (!pointCounts[pointKey]) {
        pointCounts[pointKey] = { count: 0, x, y };
      }
      pointCounts[pointKey].count++;
    }

    return Object.values(pointCounts);
  }

  static countSimilarNumbers(numbers, pairPointCount, keys, maxDistance) {
    const numberCounts = {};

    for (const num of numbers) {
      if (!numberCounts[num]) {
        numberCounts[num] = [];
      }

      for (const pair of pairPointCount) {
        const existingNum = pair[keys[0]];

        if (Math.abs(existingNum - num) <= maxDistance) {
          numberCounts[num].push(pair[keys[1]]);
        }
      }
    }

    const foundSame = {};

    for (const num in numberCounts) {
      const k = JSON.stringify(numberCounts[num]);
      const isTheSame = Object.values(numberCounts).find((n) => JSON.stringify(n) == k);
      if (isTheSame) {
        foundSame[k] = foundSame[k] || [];
        foundSame[k].push(num);
      }
    }
    const list = Object.values({ ...foundSame });

    const results = [];

    for (const l of list) {
      if (l.length == 1) {
        results.push(l[0]);
        continue;
      }

      let maxCount = 0;
      let biggest = false;

      for (const n of l) {
        let found = pairPointCount.find((p) => p[keys[0]] == n);
        if (found && found.count > maxCount) {
          biggest = n;
          maxCount = found.count;
        }
      }

      results.push(biggest);
    }

    return results.map((r) => Number(r));
  }

  static mergePointsWithinDistance(pointArray, maxDistance) {
    // Deep copy the pointArray using JSON.parse and JSON.stringify
    const mergedPoints = JSON.parse(JSON.stringify(pointArray));

    for (let i = 0; i < mergedPoints.length; i++) {
      const point1 = mergedPoints[i];

      for (let j = i + 1; j < mergedPoints.length; j++) {
        const point2 = mergedPoints[j];

        if (Math.abs(point2.x - point1.x) <= maxDistance && Math.abs(point2.y - point1.y) <= maxDistance) {
          point1.count += point2.count;
          mergedPoints.splice(j, 1);
          j--;
        }
      }
    }

    return mergedPoints;
  }

  static pointDistance(point1, point2) {
    return Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2);
  }

  static sortePairsPoint(pointCounts) {
    const sortedPointPairs = Object.keys(pointCounts)
      .sort((a, b) => pointCounts[b] - pointCounts[a])
      .map((key) => JSON.parse(key));

    return sortedPointPairs;
  }

  static chooseApproxContour(cnt, src) {
    const mapToDelete = [];
    try {
      // Approximate the contour to reduce points
      let epsilon = 0.1 * cv.arcLength(cnt, true);
      mapToDelete.push(epsilon);

      let step = epsilon / 2;
      let dir = 0;
      let lastdir = dir;
      let choosenApprox = false;

      let counter = 100;
      while (counter) {
        counter--;
        let approx = new cv.Mat();

        cv.approxPolyDP(cnt, approx, epsilon, true);
        const points = approx.rows;

        if (points === 4) {
          choosenApprox = approx;
          break;
        }
        mapToDelete.push(approx);

        lastdir = dir;
        dir = points > 4 ? 1 : -1;

        if (dir !== lastdir && lastdir) {
          step = step / 2;
        }
        epsilon = epsilon + step * dir;
      }

      // for (let i = 0; i < choosenApprox.rows; i++) {
      //   let x = choosenApprox.data32S[i * 2];
      //   let y = choosenApprox.data32S[i * 2 + 1];

      //   let point = new cv.Point(x, y);
      //   cv.circle(src, point, 7, [255, 0, 0, 0], -1);
      // }

      return choosenApprox;
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch {}
      }
    }
  }

  static findMaxCountor(img) {
    const mapToDelete = [];
    try {
      let contours = new cv.MatVector();
      mapToDelete.push(contours);
      let hierarchy = new cv.Mat();
      mapToDelete.push(hierarchy);

      cv.findContours(img, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

      const numCountours = contours.size();
      let maxArea = 0;
      let cntIndexMaxArea = 0;
      const imageArea = img.rows * img.cols * 0.8;

      cv.cvtColor(img, img, cv.COLOR_RGBA2RGB, 0);

      for (let i = 0; i < numCountours; i++) {
        let cnt = contours.get(i);
        const area = cv.contourArea(cnt);

        if (area > maxArea && area < imageArea) {
          cntIndexMaxArea = i;
          maxArea = area;
        }

        cnt.delete();
      }

      // let color = new cv.Scalar(255, 0, 0);
      // cv.drawContours(img, contours, cntIndexMaxArea, color, 1, 8, hierarchy, 0);

      return contours.get(cntIndexMaxArea);
    } catch (error) {
      console.log(error);
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch (e) {
          // logger.warn('ERR', e.message, e);
        }
      }
    }
  }

  static rotate(img) {
    cv.transpose(img, img);
    cv.flip(img, img, 0);
    return img;
  }

  static toGrayscale(img) {
    //Turning the image to greyscale
    cv.cvtColor(img, img, cv.COLOR_RGBA2GRAY, 0);
    return img;
  }

  static smoothContour(cnt) {
    const epsilon = 0.01 * cv.arcLength(cnt, true);

    cv.approxPolyDP(cnt, cnt, epsilon, true);

    // // Draw the smoothed contour on the original image
    // cv.polylines(src, poly, true, [0, 255, 0, 255], 2);
    return cnt;
  }

  static validateSize(w1, w2) {
    const widthPercents = (w1 / w2) * 100;
    return 66 < widthPercents && widthPercents < 100;
  }

  static validateRatio(w, h) {
    let checkRatio = 16 / 7;
    let m = 0.16;
    let max = checkRatio + checkRatio * m;
    let min = checkRatio - checkRatio * m;

    let rectRatio = w / h;

    return min < rectRatio && rectRatio < max;
  }

  static findCorners(rect, src) {
    // Get the four corners of the rectangle
    const points = cv.RotatedRect.points(rect);

    points.sort((a, b) => a.x - b.x);
    let tl = points[0].y < points[1].y ? points[0] : points[1];
    let bl = points[0].y < points[1].y ? points[1] : points[0];
    let tr = points[2].y < points[3].y ? points[2] : points[3];
    let br = points[2].y < points[3].y ? points[3] : points[2];

    // for (const point of points) {
    //   cv.circle(src, point, 7, [255, 0, 0, 0], -1);
    // }
    return { tl, bl, tr, br };
  }

  static blur(img) {
    let ksize;
    try {
      ksize = new cv.Size(5, 5);
      cv.GaussianBlur(img, img, ksize, 0, 0, cv.BORDER_DEFAULT);
    } finally {
      try {
        ksize.delete();
      } catch {}
    }
    return img;
  }

  static canny(img) {
    cv.Canny(img, img, 10, 200);
    return img;
  }

  static adaptiveThreshold(img) {
    cv.adaptiveThreshold(img, img, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY, 11, 2);
    return img;
  }

  static dilate(img) {
    let kernel;
    try {
      let anchor = new cv.Point(-1, -1);

      let kernel = cv.Mat.ones(5, 5, cv.CV_8U);

      cv.dilate(img, img, kernel, anchor, 1, cv.BORDER_CONSTANT);
    } finally {
      try {
        kernel.delete();
      } catch {}
    }
    return img;
  }

  static removeNoise(img) {
    let kernel;
    try {
      let anchor = new cv.Point(-1, -1);
      kernel = cv.Mat.ones(5, 5, cv.CV_8U);

      //Removing noise from the foreground objects,
      cv.morphologyEx(img, img, cv.MORPH_CLOSE, kernel, anchor, 3);
      //Removing noise from background
      cv.morphologyEx(img, img, cv.MORPH_OPEN, kernel, anchor, 1);
    } finally {
      try {
        kernel.delete();
      } catch {}
    }
    return img;
  }
}

function groupCloseValues(inputArray, threshold) {
  if (inputArray.length === 0) {
    return [];
  }

  inputArray.sort((a, b) => a - b);

  const result = [];
  let currentGroup = [inputArray[0]];

  for (let i = 1; i < inputArray.length; i++) {
    if (inputArray[i] - currentGroup[currentGroup.length - 1] <= threshold) {
      currentGroup.push(inputArray[i]);
    } else {
      result.push(currentGroup);
      currentGroup = [inputArray[i]];
    }
  }

  result.push(currentGroup);

  return result.map((group) => {
    if (group.length === 1) {
      return group[0];
    } else {
      const sum = group.reduce((acc, value) => acc + value, 0);
      return Math.round(sum / group.length);
    }
  });
}

function calculateAngle(point1, point2, point3) {
  const vector1 = { x: point2.x - point1.x, y: point2.y - point1.y };
  const vector2 = { x: point3.x - point1.x, y: point3.y - point1.y };

  const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;
  const magnitude1 = Math.sqrt(vector1.x ** 2 + vector1.y ** 2);
  const magnitude2 = Math.sqrt(vector2.x ** 2 + vector2.y ** 2);

  if (magnitude1 * magnitude2 == 0) {
    return 0;
  }

  let cosTheta = dotProduct / (magnitude1 * magnitude2);
  // Ensure cosTheta is within the valid range [-1, 1] to avoid errors in the Math.acos function.
  cosTheta = Math.min(1, Math.max(-1, cosTheta));

  const radians = Math.acos(cosTheta);
  const degrees = radians * (180 / Math.PI);

  return degrees;
}

function calculateSquareAngles(tl, tr, bl, br) {
  return {
    tl: calculateAngle(tl, bl, tr),
    tr: calculateAngle(tr, tl, br),
    bl: calculateAngle(bl, tl, br),
    br: calculateAngle(br, tr, bl),
  };
}

function isValidAngles(corners) {
  const { tl, tr, bl, br } = corners;
  const angles = calculateSquareAngles(tl, tr, bl, br);

  for (const key in angles) {
    const angle = angles[key];
    if (angle < 80 || 100 < angle) return false;
  }
  return true;
}
export { CheckDetector };
