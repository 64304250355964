import * as S from '../styles';
import { FaArrowLeft } from 'react-icons/fa';

export default function Frame({ success, toRotate, title, text, onExit, canExit }) {
  return (
    <div>
      <FrameTitle toRotate={toRotate}>{title}</FrameTitle>
      {canExit && (
        <FrameExit toRotate={toRotate} onClick={onExit}>
          <FaArrowLeft />
        </FrameExit>
      )}
      <FrameDiv toRotate={toRotate} color={success ? S.green : S.red}></FrameDiv>
      <FrameText toRotate={toRotate}>{text}</FrameText>
    </div>
  );
}
const styled = S.styled;

const FrameTitle = styled.div`
  ${({ toRotate }) =>
    toRotate
      ? `
  transform: rotate(90deg);
  left: 45%;
  top: 50%;
    `
      : `
  top: 1rem;
 
    `};

  position: fixed;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.7rem;
  font-weight: 600;
  z-index: 1;
`;

const FrameExit = styled.div`
  ${({ toRotate }) =>
    toRotate
      ? `
  transform: rotate(90deg);
  left: 91%;
  bottom: 95%;
    `
      : `
  top: 0.5rem;
  left: 0.5rem; 
  `};

  position: fixed;
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  font-weight: 600;
  z-index: 1;
  color: white;
`;

const FrameText = styled.div`
  ${({ toRotate }) =>
    toRotate
      ? `
  transform: rotate(90deg);
  right: -15vw;
  top: 50%;
  width: 100vh;

    `
      : `
      top: calc(100vh - 6rem);
      width: 100vw;

      `};

  position: fixed;
  display: flex;
  /* top: calc(100vh - 35px); */
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  z-index: 1;
  color: white;
`;

const calcWidthAndHeight = (rotate = false) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let ratio = 16 / 7;
  let width;
  let height;

  if (rotate) {
    if ((windowWidth * 0.9) / ratio < windowHeight) {
      width = '90vh';
      height = `(${width})/${ratio}`;
    } else {
      height = '90vw';
      width = `(${height})*${ratio}`;
    }
  } else {
    if ((windowWidth * 0.9) / ratio < windowHeight) {
      width = '90vw';
      height = `(${width})/${ratio}`;
    } else {
      height = '90vh';
      width = `(${height})*${ratio}`;
    }
  }

  return { height, width };
};

const FrameDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #c8cfff3b;
  border: 4px solid ${({ color }) => color};
  box-shadow: 0 0 0 100vw #00000050;
  width: calc(${calcWidthAndHeight().width});
  height: calc(${calcWidthAndHeight().height});
  ${({ toRotate }) =>
    toRotate &&
    `
      width: calc(${calcWidthAndHeight(true).height});
      height: calc(${calcWidthAndHeight(true).width});
    `};
  border-radius: 15px;
`;
