import { useNavigate } from 'react-router-dom';
import * as S from '../styles';

export default function Footer() {
  const navigate = useNavigate();

  return <FooterContainer></FooterContainer>;
}

const FooterContainer = S.styled.div`
  width: 100%;
  display: flex;
  margin: 2rem 0 0.5rem 0;
  justify-content: center;
  align-items: center;
`;
