export const primary = '#197ca8';
export const primary2 = '#1b85b5';
export const secondary = '#19A7CE';
// export const secondary = '#f6f1f1';
export const green2 = '#50b458';
export const green = '#4caf50';
export const purple = '#6C63FF';
export const orange = '#F9A826';
export const red = 'red';
export const softblack = '#272822';
export const grey = '#eee';
export const white = '#f6f1f1';
