import { useEffect, useRef, useState } from 'react';

import DataTable from 'react-data-table-component';
import * as S from '../../styles';

import { Api } from '../../api';
import { CheckDetector } from '../../tools/cv';

export default function StageCropPage() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [stages, setStages] = useState([]);
  const [error, setError] = useState('');
  const canvasRef = useRef(null);
  const canvasOrgRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const drawImageOnCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const canvasOrg = canvasOrgRef.current;
    const ctxOrg = canvasOrg.getContext('2d');
    const img = new Image();

    img.onload = () => {
      let { width, height } = img;

      const ratio = height / width;

      width = 500;
      height = width * ratio;

      canvas.width = width;
      canvas.height = height;

      canvasOrg.width = width;
      canvasOrg.height = height;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      ctxOrg.clearRect(0, 0, canvasOrg.width, canvasOrg.height);
      ctxOrg.drawImage(img, 0, 0, canvasOrg.width, canvasOrg.height);

      setStages([]);
      setError('');
    };

    img.src = selectedImage;
  };

  // Redraw the image on the canvas when the selectedImage changes
  useEffect(() => {
    if (selectedImage) {
      drawImageOnCanvas();
    }
  }, [selectedImage]);

  const handleStage = async (stage) => {
    setStages(Array.from(new Set([...stages, stage])));

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d', { willReadFrequently: true });

    const canvasOrg = canvasOrgRef.current;
    const ctxOrg = canvasOrg.getContext('2d', { willReadFrequently: true });

    try {
      await CheckDetector.detectAndCrop(canvas);
      // await cropByStages(canvasOrg, canvas, stage);
      setError('');
    } catch (e) {
      e = e.message ? e.message : e;
      setError(e);
    }
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center  p-3'>
      <input className='btn btn-outline-primary' type='file' onChange={handleImageChange} />
      {selectedImage && (
        <div className='d-flex flex-column gap-4 p-2 '>
          <div className='text-large text-center'>בחר תמונה</div>
          <div className='d-flex gap-1'>
            <div className='w-100'>
              <canvas ref={canvasRef} />
            </div>
            <div className='w-100'>
              <canvas ref={canvasOrgRef} />
            </div>
          </div>
          <div dir='ltr' className='d-flex justify-content-center align-items-center gap-2'>
            <div onClick={() => handleStage(1)} className={`btn btn-${stages.includes(1) ? '' : 'outline-'}primary text-center`}>
              1
            </div>
            <div onClick={() => handleStage(2)} className={`btn btn-${stages.includes(2) ? '' : 'outline-'}primary text-center`}>
              2
            </div>
            <div onClick={() => handleStage(3)} className={`btn btn-${stages.includes(3) ? '' : 'outline-'}primary text-center`}>
              3
            </div>
            <div onClick={() => handleStage(4)} className={`btn btn-${stages.includes(4) ? '' : 'outline-'}primary text-center`}>
              4
            </div>
            <div onClick={() => handleStage(5)} className={`btn btn-${stages.includes(5) ? '' : 'outline-'}primary text-center`}>
              5
            </div>
            <div onClick={() => handleStage(6)} className={`btn btn-${stages.includes(6) ? '' : 'outline-'}primary text-center`}>
              6
            </div>
            <div onClick={() => handleStage(7)} className={`btn btn-${stages.includes(7) ? '' : 'outline-'}primary text-center`}>
              7
            </div>
          </div>
          <ErrorText color='red' className='text-center'>
            {error}
          </ErrorText>

          <div onClick={() => setSelectedImage(selectedImage + ' ')} className={`text-white btn btn-primary text-center`}>
            Reset
          </div>
        </div>
      )}
    </div>
  );
}

const ErrorText = S.styled.div`
  color: red;
  font-weight: 600;
  font-size: small;
`;
