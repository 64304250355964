import React, { useEffect, useRef, useState } from 'react';

export default function VideoSource() {
  const [ids, setIds] = useState([]);

  const defualtConfig = {
    video: {
      facingMode: 'environment', // Use the rear-facing camera (if available)
      width: { ideal: 1920 }, // Preferred width (adjust as needed)
      height: { ideal: 1080 }, // Preferred height (adjust as needed)
      frameRate: { ideal: 30 }, // Preferred frame rate (adjust as needed)
      focusMode: 'auto', // Use autofocus (if available)
    },
    audio: false, // Disable audio (for video only)
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [cameraConfig, setCameraConfig] = useState(defualtConfig);
  const [alldata, setAllData] = useState('');
  const videoRef = useRef(null);
  let videoStream;

  const startVideo = async () => {
    try {
      videoStream = await navigator.mediaDevices.getUserMedia(cameraConfig);
      /////////////
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        const videoDevices = devices.filter((device) => device.kind === 'videoinput');

        if (videoDevices.length === 0) {
          console.log('No video cameras found.');
          return;
        }

        for (let i = 0; i < videoDevices.length; i++) {
          const deviceId = videoDevices[i].deviceId;
          const constraints = { video: { deviceId: { exact: deviceId } } };

          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          const track = stream.getVideoTracks()[0];

          console.log(`Camera ${i + 1}:`);
          console.log(`Device ID: ${deviceId}`);
          console.log(`Track Capabilities:`);
          console.log(track.getSettings());

          // Stop the camera and release the resources
          track.stop();
        }
      } catch (error) {
        console.error('Error starting cameras:', error);
      }
      ////////////

      setAllData(JSON.stringify(await navigator.mediaDevices.enumerateDevices(), null, 2));
      videoRef.current.srcObject = videoStream;
      videoRef.current.play();

      setErrorMessage('');
    } catch (error) {
      console.error('Error accessing camera:', error);
      setErrorMessage(error.message);
    }
  };

  const stopCamera = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  useEffect(() => {
    stopCamera();
    setTimeout(() => {
      startVideo();
    }, 3000);
  }, [cameraConfig]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      devices.forEach(function (device) {
        if (device.kind === 'videoinput') {
          setIds([...ids, device.deviceId]);
        }
      });
    });
    startVideo();

    return () => {
      stopCamera();
    };
  }, []);

  return (
    <div dir='ltr' className='d-flex m-2 flex-column justify-content-center align-items-center'>
      {errorMessage && (
        <div className='d-flex  m-2 flex-column justify-content-center align-items-center'>
          <div>{errorMessage}</div>
          <button onClick={() => window.location.reload()}>RELOAD</button>
        </div>
      )}
      <div className='d-flex m-2 flex-column justify-content-center align-items-center w-100'>
        <textarea
          id='textarea'
          rows='5'
          cols='50'
          className='w-50'
          defaultValue={JSON.stringify(cameraConfig, null, 2)}
        ></textarea>
        <button onClick={() => setCameraConfig(JSON.parse(document.getElementById('textarea').value))}>set</button>
        <div>{ids.length}</div>
        <input id='input1' type='number' defaultValue={0}></input>
        <button
          onClick={() => {
            const v = Number(document.getElementById('input1').value);
            setCameraConfig({
              video: {
                facingMode: 'environment',
                deviceId: { exact: ids[v] },
              },
            });
          }}
        >
          setID index
        </button>
      </div>
      <textarea id='textarea2' rows='20' cols='50' className='w-50' defaultValue={alldata}></textarea>

      <video ref={videoRef} width='50%' height='50%' playsInline muted />
    </div>
  );
}
