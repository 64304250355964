import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import sessionSlice from './sessionSlice';
import messageSlice from './messageSlice';

const persistConfig = { key: 'root', storage };

const rootReducer = combineReducers({ session: sessionSlice, message: messageSlice });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({ reducer: persistedReducer, middleware: [thunk] });
export const persistor = persistStore(store);
