import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';
import * as S from '../styles';

export default function Modal({ show, loading, title, text, onExit, canExit = true, Buttons }) {
  if (!show) return '';
  return (
    <MyModal>
      {canExit && (
        <Exit onClick={onExit}>
          <FaArrowLeft />
        </Exit>
      )}

      <ModalTitle>{title}</ModalTitle>
      <Center>
        {loading && <div className='spinner-grow' />}
        <ModalText>{text}</ModalText>
      </Center>
      {Buttons}
    </MyModal>
  );
}

const Center = S.styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ModalTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
`;

const ModalText = styled.div`
  font-weight: 600;
  color: white;
`;

const MyModal = styled.div`
  position: fixed;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${S.softblack}ef;
`;

const Exit = styled.div`
  position: fixed;
  display: flex;
  top: 10px;
  left: 10px;
  align-items: center;
  font-size: 1.7rem;
  font-weight: 600;
  z-index: 1;
  color: white;
`;
