import cv, { CV_8U } from '@techstark/opencv-js';
import Tesseract from 'tesseract.js';

async function toGrey(canvas) {
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  if (canvas.width * canvas.height === 0) return;
  let element = ctx.getImageData(0, 0, canvas.width, canvas.height);

  let src = cv.matFromImageData(element);

  try {
    cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, CV_8U); //Greyscale the src image

    cv.imshow(canvas, src);
  } catch (error) {
    console.error(error);
  } finally {
    src.delete();
  }
}

async function cutStage_1(src) {
  const mapToDelete = [];
  try {
    // cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);
    // let anchor = new cv.Point(-1, -1);
    // let kernel = cv.Mat.ones(5, 5, cv.CV_8U);
    // mapToDelete.push(kernel);
    // cv.morphologyEx(src, src, cv.MORPH_OPEN, kernel, anchor, 1);
    // cv.morphologyEx(src, src, cv.MORPH_CLOSE, kernel, anchor, 3);

    let anchor = new cv.Point(-1, -1);
    let kernel = cv.Mat.ones(5, 5, cv.CV_8U);
    mapToDelete.push(kernel);
    cv.dilate(src, src, kernel, anchor, 1, cv.BORDER_CONSTANT);

    src = CheckDetector.toGrayscale(src);

    let ksize = new cv.Size(5, 5);
    mapToDelete.push(ksize);
    cv.GaussianBlur(src, src, ksize, 0, 0, cv.BORDER_DEFAULT);

    src = CheckDetector.removeNoise(src);

    //Threshhold
    // cv.Canny(src, src, 10, 200, 3, true);

    cv.Canny(src, src, 10, 200);
    cv.adaptiveThreshold(src, src, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY, 11, 2);
    // cv.threshold(src, src, 128, 255, cv.THRESH_BINARY);

    //Finding the max contour
    let cnt = CheckDetector.findMaxCountor(src);
    mapToDelete.push(cnt);

    // cnt = CheckDetector.smoothContour(cnt);

    // Approximate the contour to reduce points
    let epsilon = 0.1 * cv.arcLength(cnt, true);
    mapToDelete.push(epsilon);

    let step = epsilon / 2;
    let dir = 0;
    let lastdir = dir;
    let choosenApprox = false;
    let counter = 100;
    while (counter) {
      counter--;
      let approx = new cv.Mat();
      mapToDelete.push(approx);

      cv.approxPolyDP(cnt, approx, epsilon, true);
      const points = approx.rows;

      if (points == 4) {
        choosenApprox = approx;
        break;
      }
      lastdir = dir;
      dir = points > 4 ? 1 : -1;

      if (dir != lastdir && lastdir) {
        step = step / 2;
      }
      epsilon = epsilon + step * dir;
    }

    if (!choosenApprox) {
      alert('not found');
    }

    const imageCorners = [
      [0, 0],
      [src.size().width - 1, 0],
      [src.size().width - 1, src.size().height - 1],
      [0, src.size().height - 1],
    ];

    let foundImageCorner = 0;

    for (let i = 0; i < choosenApprox.rows; i++) {
      let x = choosenApprox.data32S[i * 2];
      let y = choosenApprox.data32S[i * 2 + 1];

      let isImageCorner = imageCorners.find((p) => p[0] == x && p[1] == y);

      if (isImageCorner) {
        foundImageCorner++;
      }
      let point = new cv.Point(x, y);
      cv.circle(src, point, 7, [255, 0, 0, 0], -1);
    }

    if (foundImageCorner) {
      alert(foundImageCorner);
    }
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }
  }
  return src;
}

async function cutStage_2(src) {
  cv.threshold(src, src, 177, 255, cv.THRESH_BINARY);
  // cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);
  // cv.adaptiveThreshold(src, src, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY, 11, 2);

  return src;
}

async function cutStage_3(src) {
  const mapToDelete = [];
  try {
    cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);

    let mask = cv.Mat.zeros(src.size().width, src.size().height, cv.CV_8U);
    mapToDelete.push(mask);

    let bgdModel = cv.Mat.zeros(1, 65, cv.CV_64F);
    mapToDelete.push(bgdModel);

    let fgdModel = cv.Mat.zeros(1, 65, cv.CV_64F);
    mapToDelete.push(fgdModel);

    let rect = new cv.Rect(2, 2, src.size().width - 4, src.size().height - 4);

    cv.grabCut(src, mask, rect, bgdModel, fgdModel, 1, cv.GC_INIT_WITH_RECT);

    clearBackground(src, mask);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }
  }
  return src;
}

async function cutStage_4(src) {
  let ksize = new cv.Size(11, 11);
  cv.GaussianBlur(src, src, ksize, 0, 0, cv.BORDER_DEFAULT);
  return src;
}

async function cutStage_5(src) {
  cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
  cv.Canny(src, src, 0, 200, 3, false);
  return src;
}

async function cutStage_6(src) {
  const mapToDelete = [];
  try {
    let anchor = new cv.Point(-1, -1);
    let kernel = cv.Mat.ones(5, 5, cv.CV_8U);
    cv.dilate(src, src, kernel, anchor, 1, cv.BORDER_CONSTANT);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }
  }
  return src;
}

async function cutStage_7(src, org) {
  const mapToDelete = [];
  try {
    cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);

    let contours = new cv.MatVector();
    mapToDelete.push(contours);
    let hierarchy = new cv.Mat();
    mapToDelete.push(hierarchy);

    // cv.findContours(src, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);
    cv.findContours(src, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_NONE);

    let poly = new cv.MatVector();
    mapToDelete.push(poly);

    let tmp = new cv.Mat();
    mapToDelete.push(tmp);

    for (let i = 0; i < contours.size(); i++) {
      let cnt = contours.get(i);

      if (cnt.size().height < 50) continue;
      let epsilon = 0.073 * cv.arcLength(cnt, true);
      cv.approxPolyDP(cnt, tmp, epsilon, true);

      if (tmp.rows === 4) {
        poly.push_back(tmp);
        break;
      }
    }

    if (poly.size() === 0) {
      throw new Error(`לא נמצאו 4 פינות`);
    }

    cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);

    let colors = [
      [255, 0, 0],
      [0, 255, 0],
      [0, 0, 255],
    ];

    let color = new cv.Scalar(colors[0][0], colors[0][1], colors[0][2]);
    cv.drawContours(src, poly, 0, color, 1, 8, hierarchy, 0);

    //Corners
    let corners = getCorners(poly);

    cv.threshold(src, src, 177, 200, cv.THRESH_BINARY);
    //Perspective
    let pers = perspective(src, org, corners);
    mapToDelete.push(pers.dstTri);

    // Crop;
    org = crop(src, pers.dstTri);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }
  }
  return org;
}

async function perspectiveCrop(canvas, rotate) {
  let ret = { success: false, message: '' };
  if (canvas.width * canvas.height === 0) return ret;

  const ctx = canvas.getContext('2d', { willReadFrequently: true });

  const mapToDelete = [];
  try {
    let src = cv.imread(canvas);
    mapToDelete.push(src);

    let dst = cv.imread(canvas);
    mapToDelete.push(dst);

    if (rotate) {
      src = CheckDetector.rotate(src);
      dst = CheckDetector.rotate(dst);
    }

    src = CheckDetector.dilate(src);

    src = CheckDetector.toGrayscale(src);

    src = CheckDetector.blur(src);

    src = CheckDetector.removeNoise(src);

    cv.Canny(src, src, 10, 200);

    cv.adaptiveThreshold(src, src, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY, 11, 2);

    let cnt = CheckDetector.findMaxCountor(src);
    mapToDelete.push(cnt);

    // Approximate the contour to reduce points
    let epsilon = 0.1 * cv.arcLength(cnt, true);
    mapToDelete.push(epsilon);

    let step = epsilon / 2;
    let dir = 0;
    let lastdir = dir;
    let choosenApprox = false;
    mapToDelete.push(choosenApprox);

    let counter = 100;
    while (counter) {
      counter--;
      let approx = new cv.Mat();
      mapToDelete.push(approx);

      cv.approxPolyDP(cnt, approx, epsilon, true);
      const points = approx.rows;

      if (points == 4) {
        choosenApprox = approx;
        break;
      }
      lastdir = dir;
      dir = points > 4 ? 1 : -1;

      if (dir != lastdir && lastdir) {
        step = step / 2;
      }
      epsilon = epsilon + step * dir;
    }

    if (!choosenApprox) throw { myError: `וודא שהצק בתוך המסגרת` };

    const imageCorners = [
      [0, 0],
      [src.size().width - 1, 0],
      [src.size().width - 1, src.size().height - 1],
      [0, src.size().height - 1],
    ];

    let foundImageCorner = 0;

    for (let i = 0; i < choosenApprox.rows; i++) {
      let x = choosenApprox.data32S[i * 2];
      let y = choosenApprox.data32S[i * 2 + 1];

      let isImageCorner = imageCorners.find((p) => p[0] == x && p[1] == y);

      if (isImageCorner) {
        foundImageCorner++;
      }
      let point = new cv.Point(x, y);
      cv.circle(src, point, 7, [255, 0, 0, 0], -1);
    }

    let poly = new cv.MatVector();
    mapToDelete.push(poly);
    poly.push_back(choosenApprox);

    let corners = CheckDetector.getCornersFromMat(poly);

    const { tl, bl, tr } = corners;
    const recWidth = Math.sqrt(Math.pow(tl.x - tr.x, 2) + Math.pow(tl.y - tr.y, 2));
    const recHeight = Math.sqrt(Math.pow(tl.x - bl.x, 2) + Math.pow(tl.y - bl.y, 2));

    let checkRatio = 16 / 7;
    let m = 0.16;
    let max = checkRatio + checkRatio * m;
    let min = checkRatio - checkRatio * m;

    let rectRatio = recWidth / recHeight;

    let conditionRatio = min < rectRatio && rectRatio < max;

    const widthPercents = (recWidth / src.size().width) * 100;
    let conditionSize = 66 < widthPercents && widthPercents < 100;

    if (!conditionSize) {
      throw { myError: `וודא שהצ'ק ממלא את המסגרת` };
    }
    if (!conditionRatio) {
      throw { myError: `וודא שהצ'ק על רקע כהה` };
    }

    //Perspective
    let pers = CheckDetector.perspective(dst, dst, corners);
    mapToDelete.push(pers.dstTri);

    // Crop;
    dst = CheckDetector.crop(dst, pers.dstTri);
    mapToDelete.push(dst);

    ret.success = true;

    cv.imshow(canvas, dst);
  } catch (error) {
    ret.message = error.myError ? error.myError : `תקלה בזיהוי הצ'ק`;
    if (!error.myError) {
      console.log('error:', error);
    }
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }
  }
  return ret;
}

async function cropByStages(originalCanvas, canvas, stage = 1) {
  const mapToDelete = [];
  try {
    let org = cv.imread(originalCanvas);
    let src = cv.imread(canvas);
    mapToDelete.push(src);

    switch (stage) {
      case 1:
        src = await cutStage_1(src);
        break;
      case 2:
        src = await cutStage_2(src);
        break;
      case 3:
        src = await cutStage_3(src);
        break;
      case 4:
        src = await cutStage_4(src);
        break;
      case 5:
        src = await cutStage_5(src);
        break;
      case 6:
        src = await cutStage_6(src);
        break;
      case 7:
        org = await cutStage_7(src, org);
        cv.imshow(originalCanvas, org);
        break;
      default:
        break;
    }

    cv.imshow(canvas, src);

    //   //Show
    //   const canvas = createCanvas(src.size().width, src.size().height);
    //   cv.imshow(canvas, src);

    //   fs.writeFileSync(imgPath, canvas.toBuffer('image/jpeg'));

    //   timeReport.total = Date.now() - start;
    //   logger.debug('OpenCV Cutting Time:', Date.now() - start2);

    //   return { success: true };
    // } catch ({ message }) {
    //   // logger.error('OpenCV', message);

    //   return { error: message };
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }
  }
}

async function cropCheck(imgID, canvasID, rect) {
  const mapToDelete = [];
  try {
    let start = Date.now();
    let lastActionTS = start;
    const timeReport = {};

    let src = cv.imread(imgID);
    mapToDelete.push(src);
    let orig_img = cv.imread(imgID);
    mapToDelete.push(orig_img);

    let anchor = new cv.Point(-1, -1);
    let kernel = cv.Mat.ones(5, 5, cv.CV_8U);
    mapToDelete.push(kernel);

    cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);

    cv.morphologyEx(src, src, cv.MORPH_CLOSE, kernel, anchor, 3);

    timeReport.morphology = Date.now() - lastActionTS;
    lastActionTS = Date.now();

    let mask = cv.Mat.zeros(src.size().width, src.size().height, cv.CV_8U);
    mapToDelete.push(mask);
    let bgdModel = cv.Mat.zeros(1, 65, cv.CV_64F);
    mapToDelete.push(bgdModel);
    let fgdModel = cv.Mat.zeros(1, 65, cv.CV_64F);
    mapToDelete.push(fgdModel);

    rect.x = Math.max(rect.x - 20, 2);
    rect.y = Math.max(rect.y - 20, 2);
    rect.width = Math.min(rect.width + 40, src.size().width - rect.x);
    rect.height = Math.min(rect.height + 40, src.size().height - rect.y);
    rect = new cv.Rect(rect.x, rect.y, rect.width, rect.height);

    cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);
    cv.threshold(src, src, 177, 200, cv.THRESH_BINARY);

    cv.grabCut(src, mask, rect, bgdModel, fgdModel, 1, cv.GC_INIT_WITH_RECT);
    timeReport.grabCut = Date.now() - lastActionTS;
    lastActionTS = Date.now();

    // draw foreground
    clearBackground(src, mask);
    timeReport.clearBackground = Date.now() - lastActionTS;
    lastActionTS = Date.now();

    // draw grab rect
    let color = new cv.Scalar(0, 255, 0);
    let point1 = new cv.Point(rect.x, rect.y);
    let point2 = new cv.Point(rect.x + rect.width, rect.y + rect.height);
    cv.rectangle(src, point1, point2, color);

    let poly = squareEdgeDetection(src);
    mapToDelete.push(poly);

    //Corners
    let corners = getCorners(poly);
    cv.threshold(src, src, 177, 200, cv.THRESH_BINARY);

    //Perspective
    let pers = perspective(src, orig_img, corners);
    mapToDelete.push(pers.dstTri);
    timeReport.perspective = Date.now() - lastActionTS;
    lastActionTS = Date.now();

    // Crop;
    src = crop(src, pers.dstTri);
    timeReport.crop = Date.now() - lastActionTS;
    lastActionTS = Date.now();

    //Show
    cv.imshow(canvasID, src);

    const canvas = document.getElementById(canvasID);

    const base64 = canvas.toDataURL();
    timeReport.total = Date.now() - start;

    return { success: true, base64, timeReport };
  } catch (error) {
    throw error;
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        console.log('ERR', e);
      }
    }
  }
}

function crop(src, mat) {
  let rect = cv.boundingRect(mat);
  return src.roi(rect);
}

function isObjectLargeEnough(mainImageWidth, mainImageHeight, matVector) {
  // Calculate the width and height of the detected object
  let objectWidth = Math.abs(matVector.get(0).data32S[4] - matVector.get(0).data32S[0]);
  let objectHeight = Math.abs(matVector.get(0).data32S[1] - matVector.get(0).data32S[5]);

  // Check if the object's width and height are at least half of the main image's width and height
  if (objectWidth >= mainImageWidth / 4 && objectHeight >= mainImageHeight / 4) {
    return true;
  } else {
    return false;
  }
}

function getCorners(matVector, src) {
  let corners = [];
  corners.push(new cv.Point(matVector.get(0).data32S[6], matVector.get(0).data32S[7])); //A
  corners.push(new cv.Point(matVector.get(0).data32S[4], matVector.get(0).data32S[5])); //B
  corners.push(new cv.Point(matVector.get(0).data32S[0], matVector.get(0).data32S[1])); //C
  corners.push(new cv.Point(matVector.get(0).data32S[2], matVector.get(0).data32S[3])); //D

  // const redColor = new cv.Scalar(0, 0, 255, 255);
  // for (const corner of corners) {
  //   cv.circle(src, corner, 5, redColor, 15);
  // }

  corners.sort((a, b) => a.x - b.x);
  let tl = corners[0].y < corners[1].y ? corners[0] : corners[1];
  let bl = corners[0].y < corners[1].y ? corners[1] : corners[0];
  let tr = corners[2].y < corners[3].y ? corners[2] : corners[3];
  let br = corners[2].y < corners[3].y ? corners[3] : corners[2];

  return { tr, bl, br, tl };
}

function clearBackground(src, mask) {
  for (let i = 0; i < src.rows; i++) {
    for (let j = 0; j < src.cols; j++) {
      if (mask.ucharPtr(i, j)[0] === 0 || mask.ucharPtr(i, j)[0] === 2) {
        src.ucharPtr(i, j)[0] = 0;
        src.ucharPtr(i, j)[1] = 0;
        src.ucharPtr(i, j)[2] = 0;
      }
    }
  }
}

function squareEdgeDetection(src) {
  let anchor = new cv.Point(-1, -1);
  let kernel = cv.Mat.ones(5, 5, cv.CV_8U);
  let ksize = new cv.Size(11, 11);
  cv.GaussianBlur(src, src, ksize, 0, 0, cv.BORDER_DEFAULT);

  cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
  cv.Canny(src, src, 0, 200, 3, false);
  cv.dilate(src, src, kernel, anchor, 1, cv.BORDER_CONSTANT);

  let contours = new cv.MatVector();
  let hierarchy = new cv.Mat();
  cv.findContours(src, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

  let poly = new cv.MatVector();

  let tmp = new cv.Mat();

  for (let i = 0; i < contours.size(); i++) {
    let cnt = contours.get(i);

    // if (cnt.size().height < 50) continue;
    let epsilon = 0.02 * cv.arcLength(cnt, true);
    cv.approxPolyDP(cnt, tmp, epsilon, true);

    if (tmp.rows === 4) {
      poly.push_back(tmp);
      break;
    }
  }

  cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);

  let colors = [
    [255, 0, 0],
    [0, 255, 0],
    [0, 0, 255],
  ];

  if (poly.size() === 0) {
    contours.delete();
    hierarchy.delete();
    tmp.delete();
    poly.delete();
    throw new Error(`לא נמצאו 4 פינות`);
  }

  let color = new cv.Scalar(colors[0][0], colors[0][1], colors[0][2]);
  cv.drawContours(src, poly, 0, color, 1, 8, hierarchy, 0);
  contours.delete();
  hierarchy.delete();
  kernel.delete();
  tmp.delete();

  return poly;
}

function perspective(src, original, corners) {
  const mapToDelete = [];
  try {
    let { tr, br, bl, tl } = corners;

    let widthA = Math.sqrt((br.x - bl.x) ** 2 + (br.y - bl.y) ** 2);
    let widthB = Math.sqrt((tr.x - tl.x) ** 2 + (tr.y - tl.y) ** 2);
    let maxWidth = Math.max(widthA, widthB);

    let heightA = Math.sqrt((tr.x - br.x) ** 2 + (tr.y - br.y) ** 2);
    let heightB = Math.sqrt((tl.x - bl.x) ** 2 + (tl.y - bl.y) ** 2);
    let maxHeight = Math.max(heightA, heightB);

    let destination_corners = [
      [0, 0],
      [maxHeight, 0],
      [maxHeight, maxWidth],
      [0, maxWidth],
    ];

    corners = order_points(Object.values(corners));

    let corns = [];
    for (const cor of corners) {
      corns.push(cor.x);
      corns.push(cor.y);
    }

    let dest_corns = [];
    for (const cor of destination_corners) {
      dest_corns.push(cor[1]);
      dest_corns.push(cor[0]);
    }

    let srcTri = cv.matFromArray(4, 1, cv.CV_32FC2, corns);
    mapToDelete.push(srcTri);

    let dstTri = cv.matFromArray(4, 1, cv.CV_32FC2, dest_corns);
    let M = cv.getPerspectiveTransform(srcTri, dstTri);
    mapToDelete.push(M);

    let size = new cv.Size(destination_corners[1][1], destination_corners[1][0]);

    cv.warpPerspective(original, src, M, size);

    return { src, original, dstTri };
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {}
    }
  }
}

function order_points(pts) {
  let rect = Array.from({ length: 4 }, () => [0, 0]);

  let s = pts.map((p) => p.x + p.y);
  rect[0] = pts[s.indexOf(Math.min(...s))];
  rect[2] = pts[s.indexOf(Math.max(...s))];
  let diff = pts.map((p) => p.x - p.y);
  rect[1] = pts[diff.indexOf(Math.min(...diff))];
  rect[3] = pts[diff.indexOf(Math.max(...diff))];

  return rect;
}

async function readTopDigits(...params) {
  let [canvasID, destCanvas] = params;
  const canvas = document.getElementById(canvasID);
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  let element = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let src = cv.matFromImageData(element);

  try {
    let { height, width } = src.size();

    if (src.size().width < src.size().height) {
      cv.rotate(src, src, cv.ROTATE_90_COUNTERCLOCKWISE);
      height = src.size().height;
      width = src.size().width;
    }

    let rect_height = height / 5;
    let rect = new cv.Rect(0, rect_height, width / 2, rect_height);

    src = src.roi(rect);

    cv.cvtColor(src, src, cv.COLOR_BGR2GRAY);
    // cv.adaptiveThreshold(src, src, 255, cv.ADAPTIVE_THRESH_MEAN_C, cv.THRESH_BINARY_INV, 15, 2);
    // cv.threshold(src, src, 0, 255, cv.THRESH_BINARY_INV | cv.THRESH_OTSU);

    cv.imshow(destCanvas, src);

    let resultCanvas = document.getElementById(destCanvas);

    let { micr, confidence } = await getMicrFromCanvas(resultCanvas);
    // sendScanData(canvas.toDataURL(), { micr, confidence });

    if (!micr) {
      throw new Error('Reading MICR Failed');
    }

    src.delete();
    return { micr, confidence };
  } catch (error) {
    src.delete();
    return { error };
  }
}
async function getMicrFromCanvas(canvas) {
  let worker = await Tesseract.createWorker();

  await worker.loadLanguage('eng');
  await worker.initialize('eng');
  // await worker.setParameters({
  //   tessedit_char_whitelist: ' /0123456789',
  // });
  let { data } = await worker.recognize(canvas.toDataURL());
  await worker.terminate();
  let { micr, confidence } = extractMicr(data.text, data.symbols);
  return { micr, confidence };
}

function extractMicr(text, symbols) {
  //TODO: add trycatch
  let index = 0;
  let positions = [];
  let micr = text
    .split('\n')
    .filter((line) => {
      let spaces = 0;
      let notNumbers = 0;
      let tempPositions = [];
      for (let i = 0; i < line.length; i++) {
        let char = line[i];
        if (char === ' ') {
          spaces++;
        } else {
          tempPositions.push(index);
          index++;
        }
        if (isNaN(char)) notNumbers++;
      }
      if (spaces > 6 || spaces < 2 || notNumbers > 3) {
        return false;
      }
      positions.push(...tempPositions);
      return true;
    })
    .join()
    .replace(/[^0-9\s]/g, '')
    .trim();
  if (!micr) return { micr: '', confidence: 0 };
  let confidenceArray = [];

  for (const position of positions) {
    let con = symbols[position].confidence;
    confidenceArray.push(con.toFixed(2));
  }
  let confidence = Math.min(...confidenceArray);
  let splitted = micr.split(' ');
  // let temp = confidence;
  switch (splitted.length) {
    case 3:
      if (splitted[0].length < 4 || 10 < splitted[0].length) confidence -= 5;
      if (splitted[1].length !== 7) confidence -= 5;
      if (splitted[2].length < 4 || 10 < splitted[2].length) confidence -= 5;
      break;
    case 4:
      if (splitted[0].length < 4 || 10 < splitted[0].length) confidence -= 5;
      if (splitted[1].length !== 2) confidence -= 5;
      if (splitted[2].length !== 5) confidence -= 5;
      if (splitted[3].length < 4 || 10 < splitted[3].length) confidence -= 5;
      break;
    default:
      confidence -= 10;
      break;
  }

  return { micr, confidence };
}
let minBlur = false;
let blurList = [];
function blurDetection(canvas) {
  // Load the image
  let image;
  image = cv.imread(canvas);

  if (!image) {
    image.delete();
    throw new Error('Wrong input');
  }
  let gray = new cv.Mat();
  let laplacian_mat = new cv.Mat();

  cv.cvtColor(image, gray, cv.COLOR_RGBA2GRAY);

  cv.Laplacian(gray, laplacian_mat, cv.CV_64F, 1, 1, 0, cv.BORDER_DEFAULT);

  let myMean = new cv.Mat(1, 4, cv.CV_64F);
  let myStddev = new cv.Mat(1, 4, cv.CV_64F);
  cv.meanStdDev(laplacian_mat, myMean, myStddev);

  let sharpness = myStddev.doubleAt(0, 0);

  let blur = sharpness * sharpness;
  gray.delete();
  laplacian_mat.delete();
  myMean.delete();
  myStddev.delete();
  image.delete();

  blurList.push(blur);

  if (blurList.length < 5) {
    minBlur = false;
  }

  if (blurList.length >= 5) {
    minBlur = blurList.reduce((acc, num) => acc + num, 0) / blurList.length;
    if (minBlur) {
      blurList = [];
    }
  }

  if (blurList.length > 15) {
    blurList = [];
  }

  if (!minBlur) return false;

  return minBlur < blur;
}

async function perspectiveCut(canvas, corners) {
  const mapToDelete = [];
  let ret = { success: false, message: '' };

  try {
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    let element = ctx.getImageData(0, 0, canvas.width, canvas.height);

    //Creating image
    let src = cv.matFromImageData(element);
    mapToDelete.push(src);

    //Perspective
    let pers = perspective(src, src, corners);
    mapToDelete.push(pers.dstTri);

    // Crop;
    src = crop(src, pers.dstTri);

    cv.imshow(canvas, src);

    ret.success = true;
  } catch (error) {
    console.log(error);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }

    return ret;
  }
}

function findMaxCountor(src) {
  const mapToDelete = [];
  try {
    let contours = new cv.MatVector();
    mapToDelete.push(contours);
    let hierarchy = new cv.Mat();
    mapToDelete.push(hierarchy);

    cv.findContours(src, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

    const numCountours = contours.size();
    let maxArea = 0;
    let cntIndexMaxArea = 0;
    const imageArea = src.rows * src.cols * 0.8;

    cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);

    for (let i = 0; i < numCountours; i++) {
      let cnt = contours.get(i);
      const area = cv.contourArea(cnt);

      if (area > maxArea && area < imageArea) {
        cntIndexMaxArea = i;
        maxArea = area;
      }

      cnt.delete();
    }

    let color = new cv.Scalar(255, 0, 0);
    cv.drawContours(src, contours, cntIndexMaxArea, color, 1, 8, hierarchy, 0);

    return contours.get(cntIndexMaxArea);
  } catch (error) {
    console.log(error);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }
  }
}

async function drawRect3(canvas, detectBlur, toRotate) {
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  let ret = { success: false, message: '' };
  const mapToDelete = [];
  let src;
  let dst;

  try {
    if (canvas.width * canvas.height === 0) return ret;
    let element = ctx.getImageData(0, 0, canvas.width, canvas.height);

    //Creating Matrix from image
    src = cv.matFromImageData(element);
    mapToDelete.push(src);
    dst = cv.matFromImageData(element);
    mapToDelete.push(dst);

    //Rotating the image if needed
    if (toRotate) {
      cv.transpose(src, src);
      cv.flip(src, src, 0);
      cv.transpose(dst, dst);
      cv.flip(dst, dst, 0);
    }

    //Turning the image to greyscale
    cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY, 0);

    let anchor = new cv.Point(-1, -1);
    let kernel = cv.Mat.ones(5, 5, cv.CV_8U);
    mapToDelete.push(kernel);

    //Removing noise from the foreground objects,
    cv.morphologyEx(dst, dst, cv.MORPH_CLOSE, kernel, anchor, 3);
    //Removing noise from background
    cv.morphologyEx(dst, dst, cv.MORPH_OPEN, kernel, anchor, 1);

    //Threshhold
    cv.adaptiveThreshold(dst, dst, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY, 11, 2);

    //Finding the max contour
    const cnt = findMaxCountor(dst);
    mapToDelete.push(cnt);

    const epsilon = 0.01 * cv.arcLength(cnt, true);

    const approximatedContour = new cv.Mat();
    mapToDelete.push(approximatedContour);

    cv.approxPolyDP(cnt, approximatedContour, epsilon, true);
    let poly = new cv.MatVector();
    mapToDelete.push(poly);
    poly.push_back(approximatedContour);

    // // Draw the smoothed contour on the original image
    // cv.polylines(src, poly, true, [0, 255, 0, 255], 2);

    //Find the rectangle around the contour
    const rect = cv.minAreaRect(approximatedContour);

    // Get the four corners of the rectangle
    const points = cv.RotatedRect.points(rect);

    points.sort((a, b) => a.x - b.x);

    let tl = points[0].y < points[1].y ? points[0] : points[1];
    let bl = points[0].y < points[1].y ? points[1] : points[0];
    let tr = points[2].y < points[3].y ? points[2] : points[3];
    let br = points[2].y < points[3].y ? points[3] : points[2];

    const recWidth = Math.sqrt(Math.pow(tl.x - tr.x, 2) + Math.pow(tl.y - tr.y, 2));
    const recHeight = Math.sqrt(Math.pow(tl.x - bl.x, 2) + Math.pow(tl.y - bl.y, 2));

    // //Perspective
    // let pers = perspective(src, src, { tl, bl, tr, br });
    // mapToDelete.push(pers.dstTri);

    // //Crop;
    // src = crop(src, pers.dstTri);
    // mapToDelete.push(src);

    cv.imshow(canvas, src);

    let checkRatio = 16 / 7;
    let m = 0.1;
    let max = checkRatio + checkRatio * m;
    let min = checkRatio - checkRatio * m;

    let rectRatio = recWidth / recHeight;

    let conditionRatio = min < rectRatio && rectRatio < max;

    const widthPercents = (recWidth / dst.size().width) * 100;
    let conditionSize = 66 < widthPercents && widthPercents < 100;

    if (conditionRatio && conditionSize) {
      ret.success = true;
      ret.rect = rect;
    } else {
      if (!conditionSize) {
        ret.message = 'יש להתקרב';
      } else if (!conditionRatio) {
        ret.message = `מחפש צ'ק`;
      }
    }

    // if (ret.success) {
    //   if (detectBlur) {
    //     let conditionBlur = blurDetection(canvas);

    //     if (!conditionBlur) {
    //       ret.success = false;
    //       ret.message = 'החזק יציב';
    //       ret.color = 'success';
    //     }
    //   }
    // }
  } catch (error) {
    console.log(error);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }

    return ret;
  }
}
async function drawRect(canvas, detectBlur, toRotate) {
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  let ret = { success: false, message: '' };
  const mapToDelete = [];
  let src;
  let dst;

  let contours = new cv.MatVector();
  mapToDelete.push(contours);
  let hierarchy = new cv.Mat();
  mapToDelete.push(hierarchy);

  try {
    if (canvas.width * canvas.height === 0) return ret;
    let element = ctx.getImageData(0, 0, canvas.width, canvas.height);
    src = cv.matFromImageData(element);
    mapToDelete.push(src);
    dst = cv.matFromImageData(element);
    mapToDelete.push(dst);

    if (toRotate) {
      cv.transpose(src, src);
      cv.flip(src, src, 0);
      cv.transpose(dst, dst);
      cv.flip(dst, dst, 0);
    }

    cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY, 0);
    // cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0); //Greyscale the src image
    cv.threshold(dst, dst, 177, 200, cv.THRESH_BINARY);

    cv.findContours(dst, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

    // ///
    // let corners;
    // let poly;
    // try {
    //   poly = new cv.MatVector();
    //   mapToDelete.push(poly);

    //   let tmp = new cv.Mat();
    //   mapToDelete.push(tmp);

    //   for (let i = 0; i < contours.size(); i++) {
    //     let cnt = contours.get(i);

    //     if (cnt.size().height < 100) continue;
    //     let epsilon = 0.02 * cv.arcLength(cnt, true);
    //     cv.approxPolyDP(cnt, tmp, epsilon, true);

    //     if (tmp.rows === 4) {
    //       poly.push_back(tmp);

    //       break;
    //     }
    //   }

    //   corners = getCorners(poly);
    // } catch (error) {
    //   throw new Error(`לא נמצאו 4 פינות`);
    // }

    // let isLargeEnough = isObjectLargeEnough(src.size().width, src.size().height, poly);
    // if (!isLargeEnough) {
    //   throw new Error('Too samll');
    // }
    // ///
    const numCountours = contours.size();
    let maxArea = 0;
    let cntIndexMaxArea = 0;

    for (let i = 0; i < numCountours; i++) {
      let cnt = contours.get(i);
      const area = cv.contourArea(cnt);

      cntIndexMaxArea = area > maxArea ? i : cntIndexMaxArea;
      maxArea = Math.max(maxArea, area);
      cnt.delete();
    }

    let finalCnt = contours.get(cntIndexMaxArea);
    if (!finalCnt) {
      ret.message = `מחפש צ'ק`;

      cv.imshow(canvas, src);

      return;
    }

    let rect = cv.boundingRect(finalCnt);
    finalCnt.delete();
    // let rectangleColor = new cv.Scalar(255, 0, 0);
    let landscape = src.size().width > src.size().height;

    let checkRatio = landscape ? 16 / 7 : 7 / 16;
    let m = 0.2;
    let max = checkRatio + checkRatio * m;
    let min = checkRatio - checkRatio * m;
    let rectRatio = rect.width / rect.height;

    const condition_Image = rect.y + rect.height;
    let conditionImage = condition_Image < src.size().height;

    let conditionRatio = min < rectRatio && rectRatio < max;

    const condition_Size = Math.max(rect.height, rect.width);
    let conditionSize = condition_Size > Math.max(src.size().height, src.size().width) * 0.65;

    let conditionMargin =
      rect.x > 10 && rect.y > 10 && rect.x + rect.width + 10 < src.size().width && rect.y + rect.height + 10 < src.size().height;

    if (conditionImage && conditionRatio && conditionSize && conditionMargin) {
      // rectangleColor = new cv.Scalar(0, 255, 0, 255);
      ret.success = true;
      ret.rect = rect;
    } else {
      // rectangleColor = new cv.Scalar(255, 0, 0, 255);

      if (!conditionImage) {
        ret.message = `מחפש צ'ק`;
      } else if (!conditionSize || conditionMargin) {
        ret.message = "וודא שהצ'ק ממלא את המסגרת";
      } else if (!conditionRatio) {
        ret.message = 'יש להתקרב/להתרחק';
      }
      // ret.message = `Image: ${conditionImage ? 'V' : condition_Image} | Ratio: ${
      //   conditionRatio ? 'V' : rectRatio.toFixed(2)
      // } | Size: ${conditionSize ? 'V' : condition_Size} | Margin: ${conditionMargin ? 'V' : 'X'}`;
    }
    if (ret.success) {
      if (detectBlur) {
        let conditionBlur = blurDetection(canvas);

        if (!conditionBlur) {
          ret.success = false;
          ret.message = 'החזק יציב';
          ret.color = 'success';
        }
      }
    }

    cv.imshow(canvas, src);

    return ret;
  } catch (error) {
    console.log(error);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        // logger.warn('ERR', e.message, e);
      }
    }

    return ret;
  }
}

function orderCorners(points) {
  points.sort((a, b) => a.x + a.y - b.x - b.y);

  return [
    points[0], // top-left
    points[1], // top-right
    points[3], // bottom-right
    points[2], // bottom-left
  ];
}

async function drawRect2(canvas, detectBlur) {
  const mapToDelete = [];

  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  let ret = { success: false, message: '' };

  try {
    if (canvas.width * canvas.height === 0) return ret;
    let element = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let src = cv.matFromImageData(element);
    mapToDelete.push(src);

    // Convert the image to grayscale
    let src_gray = new cv.Mat();
    mapToDelete.push(src_gray);

    cv.cvtColor(src, src_gray, cv.COLOR_RGBA2GRAY);

    // Perform edge detection using Canny algorithm
    let edges = new cv.Mat();
    mapToDelete.push(edges);

    cv.Canny(src_gray, edges, 50, 150);

    // Find contours in the edge-detected image
    const contours = new cv.MatVector();
    mapToDelete.push(contours);

    let hierarchy = new cv.Mat();
    mapToDelete.push(hierarchy);

    cv.findContours(edges, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
    /////////
    ///
    let poly = new cv.MatVector();
    mapToDelete.push(poly);

    let tmp = new cv.Mat();
    mapToDelete.push(tmp);

    for (let i = 0; i < contours.size(); i++) {
      let cnt = contours.get(i);

      if (cnt.size().height < 100) continue;
      let epsilon = 0.02 * cv.arcLength(cnt, true);
      cv.approxPolyDP(cnt, tmp, epsilon, true);

      if (tmp.rows === 4) {
        poly.push_back(tmp);

        break;
      }
    }
    const corners = getCorners(poly);
    //////

    cv.threshold(src, src, 177, 200, cv.THRESH_BINARY);

    //Perspective
    // let pers = perspective(src, src, corners);
    // mapToDelete.push(pers.dstTri);

    // src = crop(src, pers.dstTri);

    cv.imshow(canvas, src);
  } catch (error) {
    console.log(error);
  } finally {
    for (const m of mapToDelete) {
      try {
        m.delete();
      } catch (e) {
        console.log('ERR', e);
      }
    }
  }
}

class CheckDetector {
  static async detect(canvas, rotate) {
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    let ret = { success: false, message: '' };
    const mapToDelete = [];

    try {
      if (canvas.width * canvas.height === 0) return ret;
      let element = ctx.getImageData(0, 0, canvas.width, canvas.height);

      //Creating Matrix from image
      let src = cv.matFromImageData(element);
      mapToDelete.push(src);

      let dst = cv.matFromImageData(element);
      mapToDelete.push(dst);

      //Rotating the image if needed
      if (rotate) {
        src = CheckDetector.rotate(src);
        dst = CheckDetector.rotate(dst);
      }

      dst = CheckDetector.toGrayscale(dst);

      dst = CheckDetector.removeNoise(dst);

      //Threshhold
      cv.adaptiveThreshold(dst, dst, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY, 11, 2);

      //Finding the max contour
      let cnt = findMaxCountor(dst);
      mapToDelete.push(cnt);

      cnt = CheckDetector.smoothContour(cnt);

      //Find the rectangle around the contour
      const rect = cv.minAreaRect(cnt);
      const rect2 = cv.boundingRect(cnt);

      const { tl, bl, tr, br } = CheckDetector.findCorners(rect, dst);

      const recWidth = Math.sqrt(Math.pow(tl.x - tr.x, 2) + Math.pow(tl.y - tr.y, 2));
      const recHeight = Math.sqrt(Math.pow(tl.x - bl.x, 2) + Math.pow(tl.y - bl.y, 2));

      cv.imshow(canvas, src);

      let conditionRatio = CheckDetector.validateRatio(recWidth, recHeight);
      let conditionSize = CheckDetector.validateSize(recWidth, dst.size().width);
      let cropped = '';

      if (conditionRatio && conditionSize) {
        ret.success = true;
        ret.rect = rect2;
        ret.cropped = cropped;
      } else {
        if (!conditionSize) {
          ret.message = 'יש להתקרב';
        } else if (!conditionRatio) {
          ret.message = `מחפש צ'ק`;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch {}
      }
    }
    return ret;
  }

  static crop(img, mat) {
    let rect = cv.boundingRect(mat);
    return img.roi(rect);
  }

  static order_points(pts) {
    let rect = Array.from({ length: 4 }, () => [0, 0]);

    let s = pts.map((p) => p.x + p.y);
    rect[0] = pts[s.indexOf(Math.min(...s))];
    rect[2] = pts[s.indexOf(Math.max(...s))];
    let diff = pts.map((p) => p.x - p.y);
    rect[1] = pts[diff.indexOf(Math.min(...diff))];
    rect[3] = pts[diff.indexOf(Math.max(...diff))];

    return rect;
  }

  static perspective(src, original, corners) {
    const mapToDelete = [];
    try {
      let { tr, br, bl, tl } = corners;

      let widthA = Math.sqrt((br.x - bl.x) ** 2 + (br.y - bl.y) ** 2);
      let widthB = Math.sqrt((tr.x - tl.x) ** 2 + (tr.y - tl.y) ** 2);
      let maxWidth = Math.max(widthA, widthB);

      let heightA = Math.sqrt((tr.x - br.x) ** 2 + (tr.y - br.y) ** 2);
      let heightB = Math.sqrt((tl.x - bl.x) ** 2 + (tl.y - bl.y) ** 2);
      let maxHeight = Math.max(heightA, heightB);

      let destination_corners = [
        [0, 0],
        [maxHeight, 0],
        [maxHeight, maxWidth],
        [0, maxWidth],
      ];

      corners = CheckDetector.order_points(Object.values(corners));

      let corns = [];
      for (const cor of corners) {
        corns.push(cor.x);
        corns.push(cor.y);
      }

      let dest_corns = [];
      for (const cor of destination_corners) {
        dest_corns.push(cor[1]);
        dest_corns.push(cor[0]);
      }

      let srcTri = cv.matFromArray(4, 1, cv.CV_32FC2, corns);
      mapToDelete.push(srcTri);

      let dstTri = cv.matFromArray(4, 1, cv.CV_32FC2, dest_corns);
      let M = cv.getPerspectiveTransform(srcTri, dstTri);
      mapToDelete.push(M);

      let size = new cv.Size(destination_corners[1][1], destination_corners[1][0]);

      cv.warpPerspective(original, src, M, size);

      return { src, original, dstTri };
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch (e) {}
      }
    }
  }

  static getCornersFromMat(matVector) {
    let corners = [];
    corners.push(new cv.Point(matVector.get(0).data32S[6], matVector.get(0).data32S[7])); //A
    corners.push(new cv.Point(matVector.get(0).data32S[4], matVector.get(0).data32S[5])); //B
    corners.push(new cv.Point(matVector.get(0).data32S[0], matVector.get(0).data32S[1])); //C
    corners.push(new cv.Point(matVector.get(0).data32S[2], matVector.get(0).data32S[3])); //D

    // const redColor = new cv.Scalar(0, 0, 255, 255);
    // for (const corner of corners) {
    //   cv.circle(src, corner, 5, redColor, 15);
    // }

    corners.sort((a, b) => a.x - b.x);
    let tl = corners[0].y < corners[1].y ? corners[0] : corners[1];
    let bl = corners[0].y < corners[1].y ? corners[1] : corners[0];
    let tr = corners[2].y < corners[3].y ? corners[2] : corners[3];
    let br = corners[2].y < corners[3].y ? corners[3] : corners[2];

    return { tr, bl, br, tl };
  }

  static findMaxCountor(img) {
    const mapToDelete = [];
    try {
      let contours = new cv.MatVector();
      mapToDelete.push(contours);
      let hierarchy = new cv.Mat();
      mapToDelete.push(hierarchy);

      cv.findContours(img, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

      const numCountours = contours.size();
      let maxArea = 0;
      let cntIndexMaxArea = 0;
      const imageArea = img.rows * img.cols * 0.8;

      cv.cvtColor(img, img, cv.COLOR_RGBA2RGB, 0);

      for (let i = 0; i < numCountours; i++) {
        let cnt = contours.get(i);
        const area = cv.contourArea(cnt);

        if (area > maxArea && area < imageArea) {
          cntIndexMaxArea = i;
          maxArea = area;
        }

        cnt.delete();
      }

      let color = new cv.Scalar(255, 0, 0);
      cv.drawContours(img, contours, cntIndexMaxArea, color, 1, 8, hierarchy, 0);

      return contours.get(cntIndexMaxArea);
    } catch (error) {
      console.log(error);
    } finally {
      for (const m of mapToDelete) {
        try {
          m.delete();
        } catch (e) {
          // logger.warn('ERR', e.message, e);
        }
      }
    }
  }

  static rotate(img) {
    cv.transpose(img, img);
    cv.flip(img, img, 0);
    return img;
  }

  static toGrayscale(img) {
    //Turning the image to greyscale
    cv.cvtColor(img, img, cv.COLOR_RGBA2GRAY, 0);
    return img;
  }

  static smoothContour(cnt) {
    const epsilon = 0.01 * cv.arcLength(cnt, true);

    cv.approxPolyDP(cnt, cnt, epsilon, true);

    // // Draw the smoothed contour on the original image
    // cv.polylines(src, poly, true, [0, 255, 0, 255], 2);
    return cnt;
  }

  static validateSize(w1, w2) {
    const widthPercents = (w1 / w2) * 100;
    return 66 < widthPercents && widthPercents < 100;
  }

  static validateRatio(w, h) {
    let checkRatio = 16 / 7;
    let m = 0.1;
    let max = checkRatio + checkRatio * m;
    let min = checkRatio - checkRatio * m;

    let rectRatio = w / h;

    return min < rectRatio && rectRatio < max;
  }
  static findCorners(rect, src) {
    // Get the four corners of the rectangle
    const points = cv.RotatedRect.points(rect);

    points.sort((a, b) => a.x - b.x);
    let tl = points[0].y < points[1].y ? points[0] : points[1];
    let bl = points[0].y < points[1].y ? points[1] : points[0];
    let tr = points[2].y < points[3].y ? points[2] : points[3];
    let br = points[2].y < points[3].y ? points[3] : points[2];

    // for (const point of points) {
    //   cv.circle(src, point, 7, [255, 0, 0, 0], -1);
    // }
    return { tl, bl, tr, br };
  }

  static blur(img) {
    let ksize;
    try {
      ksize = new cv.Size(5, 5);
      cv.GaussianBlur(img, img, ksize, 0, 0, cv.BORDER_DEFAULT);
    } finally {
      try {
        ksize.delete();
      } catch {}
    }
    return img;
  }

  static dilate(img) {
    let kernel;
    try {
      let anchor = new cv.Point(-1, -1);
      let kernel = cv.Mat.ones(5, 5, cv.CV_8U);

      cv.dilate(img, img, kernel, anchor, 1, cv.BORDER_CONSTANT);
    } finally {
      try {
        kernel.delete();
      } catch {}
    }
    return img;
  }
  static removeNoise(img) {
    let kernel;
    try {
      let anchor = new cv.Point(-1, -1);
      kernel = cv.Mat.ones(5, 5, cv.CV_8U);

      //Removing noise from the foreground objects,
      cv.morphologyEx(img, img, cv.MORPH_CLOSE, kernel, anchor, 3);
      //Removing noise from background
      cv.morphologyEx(img, img, cv.MORPH_OPEN, kernel, anchor, 1);
    } finally {
      try {
        kernel.delete();
      } catch {}
    }
    return img;
  }
}

export {
  cropByStages,
  perspectiveCrop,
  CheckDetector,
  cropCheck,
  perspectiveCut,
  readTopDigits,
  toGrey,
  drawRect,
  drawRect3,
  drawRect2,
};
