import { createSlice } from '@reduxjs/toolkit';

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    message: false,
    level: false,
    ts: false,
    index: 0,
  },
  reducers: {
    setMessage: (state, action) => {
      let { message, level, index } = action.payload;
      index = index ? index : 0;
      if (state.index < index || Date.now() - state.ts > 2000) {
        state.index = index;
        state.message = message;
        state.level = level;
        state.ts = Date.now();
      }
    },

    removeMessage: (state, action) => {
      state.message = false;
      state.level = false;
      state.index = 0;
      state.ts = false;
    },
  },
});

export const { setMessage, removeMessage } = messageSlice.actions;

export default messageSlice.reducer;
