import React from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as C from '../components';
import * as S from '../styles';

export default function Layout() {
  const session = useSelector(({ session }) => session);

  return (
    <LayoutConteiner>
      {!session.withoutLayout && <C.Header />}
      <Outlet />
      {!session.withoutLayout && <C.Footer />}
    </LayoutConteiner>
  );
}

const LayoutConteiner = S.styled.div`
  display: flex;
  flex-direction: column;
`;
